import React, { useEffect, useState, useRef } from 'react';
import loadable from '@loadable/component';
import axios from "axios";
import queryString from "query-string";
import { useAppContext } from "../../useAppState";
import { useMutation, useQuery } from '@apollo/react-hooks';
import './CoursePlay.scss';
import { Tooltip as TooltipAntd } from 'antd';
import { Button, Modal, Overlay, Tooltip } from "react-bootstrap";
import MoonLoader from "react-spinners/MoonLoader";
import { cmsGetPathCoursePlayGraphql } from '../../helpers/cmsGetFetchPaths';
import { mergeByProperty } from '../../helpers/commonsConfigElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faCalendarAlt, faList, faTimes, faCheckCircle, faTasks, faBook, faClose, faFolderTree, faClock, faHome, faFileAlt, faChalkboardTeacher, faCommentAlt, faGamepad, faLock, faPencilAlt, faFlag, faSearch, faChartPie, faRobot, faHouse, faBell, faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { isMobile } from 'react-device-detect';
import { gql } from "@apollo/client";
import { SINGLE_STUDENT_PROGRESSION, STUDENT_COURSES_NOTES, ALL_MEETING_RESERVATION, NOTIFICATION_PER_COURSE, ALL_SONDAGES_BY_COURSE, ALL_SONDAGES_ANSWERS_BY_COURSE_AND_STUDENTS } from '../../helpers/globalGQL';
import { useTranslation } from "react-i18next";
import { fancyTimeFormat } from "../../helpers/commonsConfigElements";
import ChatBotIcite from '../../components/ChatBotIcite/ChatBotIcite';
import moment from "moment";
import 'moment/min/locales'
import 'moment-timezone';
import { Select, Tag } from 'antd';
import DOMPurify from 'dompurify';
import Cookies from "js-cookie";
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const { Option } = Select;


const StatsPercentageBox = loadable(() => import('../../components/StatsPercentageBox/StatsPercentageBox'));
const CourseIntro = loadable(() => import('../../components/CourseIntro/CourseIntro'));
const NotesEditor = loadable(() => import('../../components/NotesEditor/NotesEditor'));
const DocumentsAndNotesManual = loadable(() => import('../../components/DocumentsAndNotesManual/DocumentsAndNotesManual'));



// CoursePlayExclusiveComponents  (containers)**********************************
const VideoPlayerCnt = loadable(() => import('./CoursePlayExclusiveComponents/VideoPlayerCnt'));
const StartSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/StartSlideCnt'));
const EndSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/EndSlideCnt'));
const DivisionSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/DivisionSlideCnt'));
const DocumentSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/DocumentSlideCnt'));
const HtmlSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/HtmlSlideCnt'));
const ScormSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/ScormSlideCnt'));
const ExerciceMultipleChoicesSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/ExerciceMultipleChoicesSlideCnt'));
const ExerciceOpenQuestionSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/ExerciceOpenQuestionSlideCnt'));
const RafaleSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/RafaleSlideCnt'));
const CalculationSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/CalculationSlideCnt'));
const DndImagesSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/DndImagesSlideCnt'));
const DndMultipleSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/DndMultipleSlideCnt'));
const DndTextSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/DndTextSlideCnt'));
const ExamSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/ExamSlideCnt'));
const ExamMultipleChoiceSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/ExamMultipleChoiceSlideCnt'));
const ExerciceSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/ExerciceSlideCnt'));
const AutoEvaluation1SlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/AutoEvaluation1SlideCnt'));
const MeetingReservation = loadable(() => import('./CoursePlayExclusiveComponents/MeetingReservation'));
const SondageSlideCnt = loadable(() => import('./CoursePlayExclusiveComponents/SondageSlideCnt'));
// CoursePlayExclusiveComponents  (containers)**********************************

const UPDATE_STUDENT_PROGRESSION = gql`
  mutation AddOrUpdateStudentProgression(
      $studentId: ID!, 
      $studentName: String!, 
      $courseName: String!, 
      $progressionJson: String!, 
      $requestedMeetingModule: String
  ) {
    addOrUpdateStudentProgression(
        studentId: $studentId, 
        studentName: $studentName, 
        courseName: $courseName, 
        progressionJson: $progressionJson, 
        requestedMeetingModule: $requestedMeetingModule
    )
  }
`;

const ADD_TIME_CUMULATED_TO_STUDENT_PROGRESSION = gql`
  mutation AddTimeCumulatedToStudentProgression(
      $studentId: ID!, 
      $courseName: String!, 
      $playId: Int!, 
      $secondsToAdd: Int!
  ) {
    addTimeCumulatedToStudentProgression(
        studentId: $studentId, 
        courseName: $courseName, 
        playId: $playId, 
        secondsToAdd: $secondsToAdd
    )
  }
`;

const ADD_COURSES_ACCESS_LOG = gql`
  mutation AddCoursesAccessLogs(
            $studentId: ID!, 
            $courseName: String!, 
    ) {
    addCoursesAccessLogs(
            studentId: $studentId, 
            courseName: $courseName, 
        )
  }
`;

const ADD_COURSE_TO_STUDENT = gql`
  mutation AddCourseToStudent(
            $id: ID!, 
            $coursesTypeAccess: String!, 
    ) {
    addCourseToStudent(
            id: $id, 
            coursesTypeAccess: $coursesTypeAccess, 
        )
  }
`;

const CoursePlay = (props) => {

    const { t } = useTranslation();
    const { state } = useAppContext();

    moment.locale(state.lang);

    const parsedQuery = queryString.parse(window.location.search);
    const [showModal, setShowModal] = useState(false);
    const [showAdditionalInformationModal, setShowAdditionalInformationModal] = useState(false);
    const [additionalInformationModalContent, setAdditionalInformationModalContent] = useState(null);
    const [showModalContactFiltersTeam, setShowModalContactFiltersTeam] = useState(true);
    const userAccessCourseTypes = state.userInfos.coursesTypeAccess ? JSON.parse(state.userInfos.coursesTypeAccess).coursesType : [];

    const [rawCoursesData, setRawCoursesData] = useState({});
    const [orderedCourseSlidesData, setOrderedCourseSlidesData] = useState([]);
    const [backupOrderedCourseSlidesData, setBackupOrderedCourseSlidesData] = useState([]);
    const [orderedCourseSlidesLength, setOrderedCourseSlidesLength] = useState(0);
    const [lastSlideDone, setLastSlideDone] = useState(1);
    const [filesSource, setFilesSource] = useState('');
    const [currentPlayContent, setCurrentPlayContent] = useState({ listIndex: 0 });
    const [displayMeetingSection, setDisplayMeetingSection] = useState(false);
    const [userCourseRequestedMeetings, setUserCourseRequestedMeetings] = useState(null);
    const [selectedCourseReqMeeting, setSelectedCourseReqMeeting] = useState(null);
    const [userCourseProgression, setUserCourseProgression] = useState({});
    const [playListMobileMenuClass, setPlayListMobileMenuClass] = useState('play-list-menu-mobile');
    const [playListMobileMenuBtn, setPlayListMobileMenuBtn] = useState(faList);
    const [loadingData, setLoadingData] = useState(true);
    const [videoDone, setVideoDone] = useState(false);
    const [replayVideoAvailable, setReplayVideoAvailable] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [introPageSkipped, setIntroPageSkipped] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [startSlideFromIntro, setStartSlideFromIntro] = useState(true);
    const [resetModule, setResetModule] = useState(false);
    const [resetPercentageResults, setResetPercentageResults] = useState(false);
    const [menuHidden, setMenuHidden] = useState(false);
    const [continuousMode, setContinuousMode] = useState(false);
    const [requestMeetingMode, setRequestMeetingMode] = useState(false);
    const [meetingRequired, setMeetingRequired] = useState(false);
    const [currentNotesModalCotent, setCurrentNotesModalCotent] = useState({});
    const [allNotesEditorContent, setAllNotesEditorContent] = useState({});
    const [editorClosed, setEditorClosed] = useState(true);
    const [displaySearchMenu, setDisplaySearchMenu] = useState(false);
    const [showMenuBtnTooltip, setShowMenuBtnTooltip] = useState(false);
    const [showChatBotTooltip, setShowChatBotTooltip] = useState(0);
    const [filterAndSetMenuItemsConditionsDone, setFilterAndSetMenuItemsConditionsDone] = useState(false);
    const targetMenuBtnTooltip = useRef(null);
    const [chatBotAvailable, setChatBotAvailable] = useState(false);
    const [displayChatBotBox, setDisplayChatBotBox] = useState(false);
    const [minimizedChatBox, setMinMaxChatBotBox] = useState(false);
    const [chatBotQuestions, setChatBotQuestions] = useState(null);
    const [chatBotEmailRecipients, setChatBotEmailRecipients] = useState([]);
    const [allMeetingReservations, setAllMeetingReservations] = useState([]);
    const [bannerFullHeight, setBannerFullHeight] = useState(false);
    const [allNotifications, setAllNotifications] = useState(null);
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false)
    const [notificationStatus, setNotificationStatus] = useState(null)
    const [notificationHasAlreadyOpen, setNotificationHasAlreadyOpen] = useState(false);
    const [modulesRequirementsConditions, setModulesRequirementsConditions] = useState(null);
    const [allSondages, setAllSondages] = useState([]);
    const [allSondagesAnswers, setAllSondagesAnswers] = useState([]);
    const [currentRunningSondage, setCurrentRunningSondage] = useState(null);
    const [currentRunningSondageAnswers, setCurrentRunningSondageAnswers] = useState([]);

    // *****************************************************************
    // TEAM FILTERS feature
    // *****************************************************************
    const teamsFiltersModalTexts = {
        "JaidesbesoinsspcifiquesdansgoBRIO": {
            capitalised: 'Champ d’intervention',
            lowerCase: 'champ d’intervention'
        },
        "ADPParcoursMaestroEmploys": {
            capitalised: 'Rôle',
            lowerCase: 'rôle'
        },
        "ParcoursFocusEmploys": {
            capitalised: 'Rôle',
            lowerCase: 'rôle'
        },
        "ADEParcoursAgenceHarmonia": {
            capitalised: 'Rôle',
            lowerCase: "rôle"
        },
        "Cautionnement": {
            capitalised: t('business-line-filter-name'),
            lowerCase: t('business-line-filter-name').toLowerCase()
        },
        "NSSDeveloppementpatchsurety": {
            capitalised: t('business-line-filter-name'),
            lowerCase: t('business-line-filter-name').toLowerCase()
        },
        "ContactPLCoursePrestigeRepresentatives": {
            capitalised: t('course-choice-filter-name'),
            lowerCase: t('course-choice-filter-name').toLowerCase()
        },
        "ADPParcoursinitialsouscripteursPrestigeautomobileethabitation": {
            capitalised: t('course-statement-filter-name'),
            lowerCase: t('course-statement-filter-desc').toLowerCase(),
            placeholder: t('course-statement-filter-desc-2')
        }
    };
    const teamsFiltersCourseTags = [
        "JaidesbesoinsspcifiquesdansgoBRIO",
        "ADPParcoursMaestroEmploys",
        "ParcoursFocusEmploys",
        "ADEParcoursAgenceHarmonia",
        "Cautionnement",
        "NSSDeveloppementpatchsurety",
        "ContactPLCoursePrestigeRepresentatives",
        "ADPParcoursinitialsouscripteursPrestigeautomobileethabitation"

    ];

    const teamsFilters = {
        "ADPParcoursinitialsouscripteursPrestigeautomobileethabitation": [
            {
                teamLabel: 'Je possède de l’expérience en souscription chez Intact',
                modules: ['525_html', '563_division', '464_html', '565_division', '1198_scorm', '644_scorm', '807_scorm', '294_documents', '1397_scorm', '539_html', '623_division', '630_scorm', '1573_scorm', '733_scorm', '937_scorm', '1182_scorm', '1236_scorm', '625_division', '656_scorm', '322_scorm', '112_exercicemultiplechoices', '1527_scorm', '641_scorm', '624_division', '231_scorm', '933_scorm', '813_scorm', '814_scorm', '799_scorm', '932_scorm', '800_scorm', '821_scorm', '1237_scorm', '612_division', '229_scorm', '1141_scorm', '230_scorm', '1259_scorm', '122_exercicemultiplechoices', '124_exercicemultiplechoices', '525_division', '711_video', '518_html', '641_video', '642_video', '1405_scorm', '1398_scorm', '1415_scorm', '1377_scorm', '1529_scorm', '1530_scorm', '70_end'],
            },
            {
                teamLabel: 'Je suis un nouveau souscripteur chez Intact',
                modules: ['525_html', '563_division', '524_division', '703_scorm', '1574_scorm', '1580_scorm', '568_video', '353_video', '354_video', '568_division', '635_scorm', '461_html', '570_video', '582_video', '618_video', '643_scorm', '575_video', '569_division', '795_scorm', '464_html', '608_video', '811_scorm', '630_division', '121_exercicemultiplechoices', '565_division', '1198_scorm', '644_scorm', '807_scorm', '722_scorm', '294_documents', '1397_scorm', '539_html', '623_division', '630_scorm', '1573_scorm', '733_scorm', '937_scorm', '1182_scorm', '1236_scorm', '656_scorm', '322_scorm', '112_exercicemultiplechoices', '564_division', '1527_scorm', '641_scorm', '624_division', '231_scorm', '933_scorm', '813_scorm', '814_scorm', '799_scorm', '932_scorm', '800_scorm', '821_scorm', '1237_scorm', '229_scorm', '1141_scorm', '230_scorm', '1259_scorm', '631_division', '122_exercicemultiplechoices', '124_exercicemultiplechoices', '525_division', '669_video', '711_video', '518_html', '613_division', '635_video', '1043_scorm', '1044_scorm', '1410_scorm', '1045_scorm', '1411_scorm', '1046_scorm', '640_video', '641_video', '1413_scorm', '1414_scorm', '1047_scorm', '642_video', '1405_scorm', '1398_scorm', '1415_scorm', '1388_scorm', '1399_scorm', '614_division', '636_video', '1054_scorm', '1057_scorm', '1419_scorm', '1418_scorm', '1421_scorm', '1422_scorm', '1423_scorm', '1050_scorm', '615_division', '637_video', '1065_scorm', '1066_scorm', '1424_scorm', '1425_scorm', '1067_scorm', '1069_scorm', '1390_scorm', '1400_scorm', '616_division', '638_video', '1072_scorm', '1394_scorm', '1428_scorm', '1429_scorm', '1431_scorm', '1401_scorm', '617_division', '639_video', '1077_scorm', '1085_scorm', '1081_scorm', '1377_scorm', '1118_scorm', '1082_scorm', '1084_scorm', '1053_scorm', '629_scorm', '629_division', '772_video', '1522_scorm', '1529_scorm', '1530_scorm', '536_html', '1528_scorm', '618_division', '1412_scorm', '1420_scorm', '1426_scorm', '1427_scorm', '70_end'],
            },
        ],
        "Cautionnement": [
            {
                teamLabel: 'Contrat',
                modules: ['83_start', '522_html', '576_division', '582_division', '146_exercicemultiplechoices', '583_division', '131_exercicemultiplechoices', '584_division', '133_exercicemultiplechoices', '600_division', '586_division', '578_division', '483_html', '507_html', '129_exercicemultiplechoices', '486_html', '139_exercicemultiplechoices', '487_html', '143_exercicemultiplechoices', '488_html', '489_html', '141_exercicemultiplechoices', '580_division', '490_html', '491_html', '492_html', '113_exerciceopenquestion', '493_html', '135_exercicemultiplechoices', '494_html', '137_exercicemultiplechoices', '495_html', '111_exerciceopenquestion', '67_end'],
            },
            {
                teamLabel: 'Commercial',
                modules: ['83_start', '522_html', '576_division', '582_division', '146_exercicemultiplechoices', '600_division', '602_division', '603_division', '604_division', '578_division', '483_html', '507_html', '129_exercicemultiplechoices', '486_html', '139_exercicemultiplechoices', '487_html', '143_exercicemultiplechoices', '488_html', '489_html', '141_exercicemultiplechoices', '580_division', '490_html', '491_html', '492_html', '113_exerciceopenquestion', '493_html', '135_exercicemultiplechoices', '494_html', '137_exercicemultiplechoices', '495_html', '111_exerciceopenquestion', '67_end'],
            },
        ],
        "NSSDeveloppementpatchsurety": [
            {
                teamLabel: 'Contract',
                modules: ['84_start', '523_html', '577_division', '588_division', '147_exercicemultiplechoices', '592_division', '132_exercicemultiplechoices', '589_division', '134_exercicemultiplechoices', '601_division', '591_division', '579_division', '496_html', '508_html', '130_exercicemultiplechoices', '497_html', '140_exercicemultiplechoices', '498_html', '144_exercicemultiplechoices', '499_html', '500_html', '142_exercicemultiplechoices', '581_division', '501_html', '502_html', '503_html', '114_exerciceopenquestion', '504_html', '136_exercicemultiplechoices', '505_html', '138_exercicemultiplechoices', '506_html', '112_exerciceopenquestion', '68_end'],
            },
            {
                teamLabel: 'Commercial',
                modules: ['84_start', '523_html', '577_division', '588_division', '147_exercicemultiplechoices', '601_division', '591_division', '603_division', '604_division', '579_division', '496_html', '508_html', '130_exercicemultiplechoices', '497_html', '140_exercicemultiplechoices', '498_html', '144_exercicemultiplechoices', '499_html', '500_html', '142_exercicemultiplechoices', '581_division', '501_html', '502_html', '503_html', '114_exerciceopenquestion', '504_html', '136_exercicemultiplechoices', '505_html', '138_exercicemultiplechoices', '506_html', '112_exerciceopenquestion', '68_end'],
            },
        ],
        "ContactPLCoursePrestigeRepresentatives": [
            {
                teamLabel: 'Integral Contact PL Course',
                modules: ['521_html', '598_division', '761_video', '765_video', '742_video', '743_video', '744_video', '745_video', '762_video', '323_documents', '324_documents', '325_documents', '746_video', '763_video', '766_video', '747_video', '748_video', '326_documents', '327_documents', '767_video', '768_video', '328_documents', '749_video', '520_html', '750_video', '751_video', '752_video', '764_video', '753_video', '329_documents', '330_documents', '331_documents', '332_documents', '754_video', '333_documents', '334_documents', '335_documents', '756_video', '757_video', '759_video', '760_video', '145_exercicemultiplechoices', '645_division', '780_video', '776_video', '157_exercicemultiplechoices', '778_video', '158_exercicemultiplechoices', '779_video', '155_exercicemultiplechoices', '781_video', '160_exercicemultiplechoices', '545_html', '159_exercicemultiplechoices', '71_end', '341_documents', '342_documents'],
            },
            {
                teamLabel: 'Expertise Contact PL Course',
                modules: ['521_html', '599_division', '765_video', '766_video', '767_video', '768_video', '328_documents', '645_division', '780_video', '776_video', '157_exercicemultiplechoices', '778_video', '158_exercicemultiplechoices', '779_video', '155_exercicemultiplechoices', '781_video', '160_exercicemultiplechoices', '545_html', '159_exercicemultiplechoices', '71_end', '341_documents', '342_documents'],

            },
        ],
        "JaidesbesoinsspcifiquesdansgoBRIO": [
            {
                teamLabel: 'Accès courtiers',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '326_division', '636_video', '1054_scorm', '327_division', '637_video', '1069_scorm', '329_division', '639_video', '1077_scorm', '1082_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Engagement numérique - Service à la clientèle (SAC)',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '326_division', '636_video', '1054_scorm', '327_division', '1071_scorm', '329_division', '639_video', '1077_scorm', '1081_scorm', '1082_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Gestion de projet',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '1046_scorm', '1047_scorm', '1048_scorm', '640_video', '326_division', '636_video', '1054_scorm', '1057_scorm', '327_division', '637_video', '1065_scorm', '1066_scorm', '1069_scorm', '1071_scorm', '328_division', '638_video', '1072_scorm', '1074_scorm', '329_division', '639_video', '1077_scorm', '1082_scorm', '1084_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Marketing & Communications',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Perception',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1145_scorm', '1045_scorm', '326_division', '636_video', '1054_scorm', '1057_scorm', '327_division', '1071_scorm', '329_division', '639_video', '1077_scorm', '1081_scorm', '1082_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Prévention',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '1046_scorm', '640_video', '1047_scorm', '326_division', '636_video', '1054_scorm', '327_division', '637_video', '1069_scorm', '329_division', '639_video', '1077_scorm', '1081_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'PSI & solutions technologiques',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '1046_scorm', '640_video', '1047_scorm', '1048_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Réception',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Solutions spécialisées agricoles',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '329_division', '639_video', '1082_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Télésolutions',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '326_division', '636_video', '1054_scorm', '327_division', '1051_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
            {
                teamLabel: 'Autres',
                modules: ['66_start', '374_html', '648_video', '52_end', '325_division', '635_video', '1043_scorm', '1044_scorm', '1120_scorm', '1143_scorm', '1045_scorm', '326_division', '636_video', '1054_scorm', '327_division', '637_video', '1065_scorm', '1066_scorm', '328_division', '638_video', '1072_scorm', '329_division', '639_video', '1081_scorm', '1118_scorm', '1082_scorm', '1084_scorm', '337_division', '79_exerciceopenquestion', '377_html'],
            },
        ],
        "ADPParcoursMaestroEmploys": [
            {
                teamLabel: 'Souscription',
                modules: ['74_start', '1327_scorm', '670_video', '671_video', '672_video', '278_documents', '279_documents', '673_video', '674_video', '675_video', '280_documents', '676_video', '677_video', '678_video', '447_html', '726_video', '85_exerciceopenquestion', '62_end'],
            },
            {
                teamLabel: 'Aide-souscription',
                modules: ['74_start', '1327_scorm', '670_video', '671_video', '672_video', '278_documents', '683_video', '679_video', '286_documents', '279_documents', '673_video', '284_documents', '674_video', '675_video', '673_video', '281_documents', '676_video', '677_video', '680_video', '678_video', '447_html', '727_video', '85_exerciceopenquestion', '62_end'],
            },
            {
                teamLabel: 'Coordonateurs',
                modules: ['74_start', '1327_scorm', '670_video', '671_video', '672_video', '688_video', '287_documents', '278_documents', '683_video', '681_video', '279_documents', '673_video', '284_documents', '674_video', '675_video', '684_video', '685_video', '677_video', '680_video', '682_video', '678_video', '447_html', '85_exerciceopenquestion', '62_end'],
            },
            {
                teamLabel: 'Équipe soutien corporatif',
                modules: ['74_start', '1327_scorm', '670_video', '671_video', '672_video', '278_documents', '675_video', '282_documents', '676_video', '285_documents', '678_video', '447_html', '85_exerciceopenquestion', '62_end'],
            },
        ],
        "ADEParcoursAgenceHarmonia": [
            {
                teamLabel: 'Nouveau dans le domaine de l\'assurance',
                modules: ['69_start', '377_html', '552_division', '62_scorm', '132_scorm', '65_scorm', '1373_scorm', '1374_scorm', '403_scorm', '592_scorm', '449_scorm', '119_scorm', '1371_scorm', '614_scorm', '1217_scorm', '553_division', '622_division', '1622_scorm', '1486_scorm', '1504_scorm', '1515_scorm', '1545_scorm', '1581_scorm', '405_video', '411_video', '1166_scorm', '361_division', '556_scorm', '94_scorm', '103_scorm', '362_division', '980_division', '980_scorm', '510_scorm', '95_scorm', '378_html', '555_division', '396_video', '70_scorm', '1328_scorm', '381_html', '1372_scorm', '443_scorm', '458_scorm', '380_html', '385_html', '405_html', '156_division', '509_scorm', '175_scorm', '177_scorm', '67_scorm', '68_scorm', '386_html', '365_division', '1219_scorm', '979_scorm', '1220_scorm', '1653_scorm', '1227_scorm', '1221_scorm', '382_html', '105_scorm', '106_scorm', '367_division', '1223_scorm', '383_html', '368_division', '1224_scorm', '384_html', '23_html', '387_html', '235_scorm', '613_video', '370_division', '462_scorm', '485_scorm', '486_scorm', '101_scorm', '264_scorm', '487_scorm', '115_scorm', '488_scorm', '489_scorm', '382_division', '358_division', '94_video', '98_video', '99_video', '77_exercicemultiplechoices', '283_scorm', '284_scorm', '285_scorm', '1208_scorm', '97_video', '366_division', '245_scorm', '1210_scorm', '1209_scorm', '1211_scorm', '1212_scorm', '1213_scorm', '1214_scorm', '359_division', '1139_scorm', '1215_scorm', '88_exerciceopenquestion', '58_end']
            },
            {
                teamLabel: 'Certifié en ADP et nouvellement certifié en ADE',
                modules: ['69_start', '377_html', '550_division', '551_division', '1409_scorm', '62_scorm', '132_scorm', '65_scorm', '1373_scorm', '1359_scorm', '1374_scorm', '403_scorm', '987_scorm', '592_scorm', '983_scorm', '449_scorm', '986_scorm', '119_scorm', '1371_scorm', '614_scorm', '989_scorm', '1217_scorm', '993_scorm', '553_division', '622_division', '1622_scorm', '1486_scorm', '1504_scorm', '1515_scorm', '1545_scorm', '1581_scorm', '554_division', '360_division', '405_video', '411_video', '1166_scorm', '1362_scorm', '361_division', '556_scorm', '985_scorm', '94_scorm', '103_scorm', '362_division', '980_division', '980_scorm', '510_scorm', '988_scorm', '95_scorm', '378_html', '555_division', '396_video', '70_scorm', '1328_scorm', '381_html', '1372_scorm', '443_scorm', '458_scorm', '380_html', '385_html', '405_html', '156_division', '509_scorm', '984_scorm', '175_scorm', '177_scorm', '67_scorm', '68_scorm', '386_html', '365_division', '1219_scorm', '979_scorm', '1220_scorm', '994_scorm', '1227_scorm', '1234_scorm', '1221_scorm', '1653_scorm', '382_html', '105_scorm', '106_scorm', '992_scorm', '367_division', '1223_scorm', '991_scorm', '383_html', '368_division', '1224_scorm', '995_scorm', '384_html', '23_html', '387_html', '235_scorm', '613_video', '370_division', '462_scorm', '485_scorm', '486_scorm', '101_scorm', '264_scorm', '487_scorm', '115_scorm', '488_scorm', '489_scorm', '997_scorm', '382_division', '358_division', '94_video', '98_video', '99_video', '77_exercicemultiplechoices', '283_scorm', '284_scorm', '285_scorm', '1208_scorm', '1231_scorm', '97_video', '366_division', '245_scorm', '1088_scorm', '1210_scorm', '1209_scorm', '1211_scorm', '1212_scorm', '1213_scorm', '1214_scorm', '1216_scorm', '359_division', '1089_scorm', '1215_scorm', '1226_scorm', '88_exerciceopenquestion', '58_end'
                ],
            },
            {
                teamLabel: 'Certifié en ADE et nouvel utilisateur Contact AE',
                modules: ['69_start', '377_html', '550_division', '551_division', '1409_scorm', '62_scorm', '132_scorm', '65_scorm', '1373_scorm', '1359_scorm', '1374_scorm', '403_scorm', '987_scorm', '592_scorm', '983_scorm', '449_scorm', '986_scorm', '119_scorm', '1371_scorm', '614_scorm', '989_scorm', '1217_scorm', '993_scorm', '553_division', '622_division', '1622_scorm', '1486_scorm', '1504_scorm', '1515_scorm', '1545_scorm', '1581_scorm', '554_division', '360_division', '405_video', '411_video', '1166_scorm', '1362_scorm', '361_division', '556_scorm', '985_scorm', '94_scorm', '103_scorm', '362_division', '980_division', '980_scorm', '510_scorm', '988_scorm', '95_scorm', '378_html', '555_division', '396_video', '70_scorm', '1328_scorm', '381_html', '1372_scorm', '443_scorm', '458_scorm', '380_html', '385_html', '405_html', '156_division', '509_scorm', '984_scorm', '175_scorm', '177_scorm', '67_scorm', '68_scorm', '386_html', '365_division', '1219_scorm', '979_scorm', '1220_scorm', '994_scorm', '1227_scorm', '1234_scorm', '1221_scorm', '1653_scorm', '382_html', '105_scorm', '106_scorm', '992_scorm', '367_division', '1223_scorm', '991_scorm', '383_html', '368_division', '1224_scorm', '995_scorm', '384_html', '23_html', '387_html', '235_scorm', '613_video', '370_division', '462_scorm', '485_scorm', '486_scorm', '101_scorm', '264_scorm', '487_scorm', '115_scorm', '488_scorm', '489_scorm', '997_scorm', '382_division', '358_division', '94_video', '98_video', '99_video', '77_exercicemultiplechoices', '283_scorm', '284_scorm', '285_scorm', '1208_scorm', '1231_scorm', '97_video', '366_division', '245_scorm', '1088_scorm', '1210_scorm', '1209_scorm', '1211_scorm', '1212_scorm', '1213_scorm', '1214_scorm', '1216_scorm', '359_division', '1089_scorm', '1215_scorm', '1226_scorm', '88_exerciceopenquestion', '58_end'
                ],
            },
        ],
        "ParcoursFocusEmploys": [
            {
                teamLabel: 'Agent, Réception et Services partagés',
                modules: ['78_start', '1368_scorm', '553_html', '303_documents', '690_video', '1548_scorm', '703_video', '298_documents', '704_video', '701_video', '299_documents', '692_video', '693_video', '694_video', '300_documents', '702_video', '695_video', "699_video", '696_video', '301_documents', '302_documents', '297_documents', '709_video', '526_html', '607_division', '11_exammultiplechoice', '13_exammultiplechoice', '14_exammultiplechoice', '88_exerciceopenquestion',
                    //  attendre confirmation de Sarah pour débloquer les bloc ou les retirer des filtre
                    //  '543_division', '544_division', '545_division', '546_division', '547_division', '548_division', '549_division',
                    '66_end'],
            },
            {
                teamLabel: 'Gestionnaire et Formateur',
                modules: ['78_start', '1368_scorm', '303_documents', '690_video', '1548_scorm', '703_video', '298_documents', '704_video', '701_video', '299_documents', '692_video', '693_video', '694_video', '300_documents', '702_video', '695_video', "699_video", '696_video', '301_documents', '302_documents', "553_html", "297_documents", "526_html", "709_video", "708_video", "705_video", "706_video", "707_video", "12_exammultiplechoice", '88_exerciceopenquestion',
                    //  attendre confirmation de Sarah pour débloquer les bloc ou les retirer des filtre
                    //  '543_division', '544_division', '545_division', '546_division', '547_division', '548_division', '549_division', 
                    "66_end"],
            },
        ],

    };
    const [contactTeamSelected, setContactTeamSelected] = useState(null);
    // *****************************************************************
    // CONTACT TEAM FILTERS
    // *****************************************************************

    // *****************************************************************
    // Custonm  - Rencontres Intact - Ambassadeurs Contact AP
    // *****************************************************************

    const isParcoursRencontreAmbassadeurs = parsedQuery.tag === 'RencontreIntactambassadeursContactAP';

    useQuery(STUDENT_COURSES_NOTES,
        {
            skip: Object.keys(state.userInfos).length <= 0,
            variables: {
                studentId: parseInt(state.userInfos.id),
                courseName: parsedQuery.tag
            },
            onCompleted(res) {
                if (res) {
                    if (res.singleCoursesStudentNotes) {
                        setAllNotesEditorContent(JSON.parse(res.singleCoursesStudentNotes.notesEditorJson));
                    }
                }
            }
        })

    //Fetch all notifications (Wait until we get cohort of the user)
    const { refetch: refetchAllNotification } = useQuery(NOTIFICATION_PER_COURSE,
        {
            notifyOnNetworkStatusChange: true,
            skip: !state.userInfos.cohortes,
            variables: {
                course: parsedQuery.tag,
            },
            onCompleted(res) {

                const allNotificationsWithStatus = res.notificationPerCourse
                const allNotificationsFilterCohorts = []


                allNotificationsWithStatus.forEach((notification) => {
                    //Filter if cohort field isn't empty

                    if (
                        notification.courses &&
                        JSON.parse(notification.courses)[parsedQuery.tag]
                    ) {
                        if (notification.cohorts !== null) {
                            JSON.parse(notification.cohorts).forEach((cohorte) => {
                                if (state && state.userInfos.cohortes.length > 0) {
                                    state.userInfos.cohortes.forEach((cohorteStudent) => {
                                        if (cohorte === cohorteStudent.cohorteId && !allNotificationsFilterCohorts.includes(notification)) {
                                            allNotificationsFilterCohorts.push(notification);
                                        }
                                    })
                                }
                            })
                        } else {
                            allNotificationsFilterCohorts.push(notification);
                        }
                    }
                })
                setAllNotifications(allNotificationsFilterCohorts);
            }
        }
    );

    const { loading: sondagesByCourseLoading } = useQuery(ALL_SONDAGES_BY_COURSE,
        {
            variables: {
                courseName: parsedQuery.tag
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
            notifyOnNetworkStatusChange: true,
            onError(err) {
                console.log(err)
            },
            onCompleted(res) {

                let resData = res.allSondagesByCourse;

                if (resData) {
                    console.log('ALL_SONDAGES_BY_COURSE', resData);

                    setAllSondages(resData)
                }


            }
        })

    const { loading: allSondageAnswersbyCourseAndStudentLoading, refetch: refetchSondagesAnswers } = useQuery(ALL_SONDAGES_ANSWERS_BY_COURSE_AND_STUDENTS,
        {
            variables: {
                studentId: parseInt(state.userInfos.id),
                courseName: parsedQuery.tag
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
            notifyOnNetworkStatusChange: true,
            onError(err) {
                console.log(err)
            },
            onCompleted(res) {

                let resData = res.allSondageAnswersbyCourseAndStudent;

                console.log('ALL_SONDAGES_ANSWERS_BY_COURSE_AND_STUDENTS', resData);

                if (resData) {
                    setAllSondagesAnswers(resData)
                }


            }
        })

    //Show notifications modal
    const showModalNotification = () => {
        setIsNotificationModalOpen(true);
        setNotificationHasAlreadyOpen(true);
    };

    //Close notifications modal
    const handleOkModalNotification = () => {
        setIsNotificationModalOpen(false);
        setNotificationHasAlreadyOpen(true);
    };

    const { loading: loadingProgression } = useQuery(SINGLE_STUDENT_PROGRESSION,
        {
            skip: Object.keys(state.userInfos).length <= 0,
            variables: {
                studentId: parseInt(state.userInfos.id),
                courseName: parsedQuery.tag
            },
            onCompleted(res) {
                if (res) {
                    let responseProgression = res.singleStudentsProgression
                    let savedProgression = [];

                    /***************SEND ACCESSLOG INPUT*******************/
                    if (Object.keys(state.userInfos).length > 0) {
                        addCoursesAccessLog({
                            variables: {
                                studentId: state.userInfos.id,
                                courseName: parsedQuery.tag
                            }
                        })
                    }

                    /***************GET COURSE CONTENT FROM CMS AND SET ALL NECESSARY CONFIGS TO START COURSEPLAY*******************/
                    axios.post(state.cms2_baseURL + '/graphql', cmsGetPathCoursePlayGraphql(parsedQuery.tag), {
                        headers: {
                            authorization: `Bearer ${process.env.REACT_APP_CMS_API_TOKEN}`
                        }
                    }).then(result => {
                        const courseData = result.data.data.model_courses[0];

                        console.log(courseData);

                        console.log('enable_modules_requirements', courseData.enable_modules_requirements);
                        console.log('modules_requirements_conditions', courseData.modules_requirements_conditions);

                        if (courseData.enable_modules_requirements && courseData.modules_requirements_conditions && courseData.modules_requirements_conditions.length > 0) {
                            setModulesRequirementsConditions(courseData.modules_requirements_conditions.map(conditionItem => {
                                return {
                                    module_blocked: conditionItem.module_blocked,
                                    modules_required: conditionItem.modules_required.split(',')
                                }
                            }))
                        }




                        const courseTypes = courseData['course_types'] ? courseData['course_types'].map(cType => cType['course_types_id']['course_type']) : []

                        let isCourseContinuous = false;
                        let isRequestMeeting = false;
                        let isMeetingRequired = false;

                        // ***************************************************************************************
                        // Conditions (isCourseContinuous and isRequestMeeting) for cohortes
                        // ***************************************************************************************
                        if (state.userInfos.cohortesJson) {
                            const cohorteCourse = state.userInfos.cohortesJson.find(elCohort => courseTypes.includes(elCohort.course) || courseData['course_tag'] === elCohort.course);
                            let thisCourseCohorte = cohorteCourse ? state.userInfos.cohortes.filter(cohorte => cohorte.cohorteId === cohorteCourse.cohorteID) : []


                            if (thisCourseCohorte.length > 0) {
                                thisCourseCohorte = thisCourseCohorte[0]
                                if (thisCourseCohorte.continuousModeCourses) {
                                    JSON.parse(thisCourseCohorte.continuousModeCourses).map(cType => {
                                        if (courseTypes.includes(cType.tag) || cType.tag === parsedQuery.tag) {
                                            isCourseContinuous = true

                                            console.log(cType);
                                            if (cType.requestMeeting) {
                                                isRequestMeeting = true
                                                isMeetingRequired = true
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        // ***************************************************************************************
                        // Conditions (isCourseContinuous and isRequestMeeting) for cohortes
                        // ***************************************************************************************



                        // ***************************************************************************************
                        // Conditions (isCourseContinuous and isRequestMeeting) for general
                        // ***************************************************************************************
                        if (
                            (courseData.reading_mode_internal === 'continuous' && state.userInfos.isInternal) ||
                            (courseData.reading_mode_external === 'continuous' && !state.userInfos.isInternal)
                        ) {
                            isCourseContinuous = true
                        }

                        if (courseData.request_meeting_mode) {
                            isRequestMeeting = true
                        }

                        if (
                            (courseData.required_meeting_mode_internal && state.userInfos.isInternal) ||
                            (courseData.required_meeting_mode_external && !state.userInfos.isInternal)
                        ) {
                            isMeetingRequired = true
                        }
                        // ***************************************************************************************
                        // Conditions (isCourseContinuous and isRequestMeeting) for general
                        // ***************************************************************************************


                        let modulesToLoopCondition = [
                            { type: 'video', typeIdFieldName: 'video_type_id', menuIcon: faYoutube },
                            { type: 'exercice', typeIdFieldName: 'exercice_type_id', menuIcon: faTasks },
                            { type: 'exam', typeIdFieldName: 'exam_type_id', menuIcon: faTasks },
                            { type: 'exammultiplechoice', typeIdFieldName: 'exam_multiple_choice_type_id', menuIcon: faTasks },
                            { type: 'scorm', typeIdFieldName: 'scorm_type_id', menuIcon: faChalkboardTeacher },
                            { type: 'dndtext', typeIdFieldName: 'drag_and_drop_text_type_id', menuIcon: faGamepad },
                            { type: 'dndmultiple', typeIdFieldName: 'drag_and_drop_text_multiple_targets_type_id', menuIcon: faGamepad },
                            { type: 'dndimages', typeIdFieldName: 'drag_and_drop_images_type_id', menuIcon: faGamepad },
                            { type: 'calculation', typeIdFieldName: 'calculation_type_id', menuIcon: faGamepad },
                            { type: 'rafale', typeIdFieldName: 'rafale_type_id', menuIcon: faGamepad },
                            { type: 'documents', typeIdFieldName: 'documents_type_id', menuIcon: faFileAlt },
                            { type: 'html', typeIdFieldName: 'html_type_id', menuIcon: faCommentAlt },
                            { type: 'exerciceopenquestion', typeIdFieldName: 'exercice_open_question_type_id', menuIcon: faTasks },
                            { type: 'exercicemultiplechoices', typeIdFieldName: 'exercice_multiple_choices_type_id', menuIcon: faTasks },
                            { type: 'start', typeIdFieldName: 'start_type_id', menuIcon: faHome },
                            { type: 'end', typeIdFieldName: 'end_type_id', menuIcon: faFlag },
                            { type: 'division', typeIdFieldName: 'division_type_id', menuIcon: faChartPie },
                            { type: 'autoevaluation1', typeIdFieldName: 'auto_evaluation_1_type_id', menuIcon: faChartPie },
                        ]

                        // let orderedData = courseData['courses_modules'].sort((a, b) => a.sort - b.sort).map((item, index) => {
                        //     let playType = item['modules_id']['type']
                        //     let playContent = {}

                        //     modulesToLoopCondition.map(moduleItem => {
                        //         if (item['modules_id']['type'] === moduleItem.type) {
                        //             playContent = item['modules_id']['module_' + moduleItem.type + '_content'][0][moduleItem.typeIdFieldName]
                        //             playContent['menuIcon'] = moduleItem.menuIcon;
                        //         }
                        //     })

                        //     return ({ ...playContent, playType: playType, listIndex: index, course_tag: parsedQuery.tag })
                        // });

                        // Pre-calculate this to avoid repetitive string operations within the loop
                        const moduleMap = {};
                        modulesToLoopCondition.forEach(moduleItem => {
                            moduleMap[moduleItem.type] = {
                                typeIdFieldName: moduleItem.typeIdFieldName,
                                menuIcon: moduleItem.menuIcon
                            };
                        });

                        // Main transformation
                        let orderedData = courseData.courses_modules
                            .sort((a, b) => a.sort - b.sort)
                            .map((item, index) => {
                                let moduleType = item.modules_id.type;
                                let playContent = {};

                                if (moduleMap[moduleType]) {
                                    playContent = item.modules_id['module_' + moduleType + '_content'][0][moduleMap[moduleType].typeIdFieldName];
                                    playContent.menuIcon = moduleMap[moduleType].menuIcon;
                                }


                                return {
                                    ...playContent,
                                    playType: moduleType,
                                    listIndex: index,
                                    course_tag: parsedQuery.tag,
                                    moduleContainerId: item.modules_id.id,
                                    ...(item.modules_id.display_additional_information ? { additionalInformation: item.modules_id.additional_information } : {}),
                                };
                            });


                        // ******************************************************************
                        // SET BACKUP ORDERED COURSES LIST DATA BEFORE ANY REORDERING/FILTERING
                        setBackupOrderedCourseSlidesData(orderedData);

                        /***************SET PROGRESSION IF EXIST + ADD LISTINDEX TO REQUESTED MEETINGS ITEMS*******************/
                        if (responseProgression) {
                            if (responseProgression.progressionJson) {
                                savedProgression = JSON.parse(responseProgression.progressionJson)
                            }

                            addLisIndexToRequestedMeeting(responseProgression.requestedMeetings, orderedData)
                        }


                        let progressionLayout = orderedData.map((item, i) => ({
                            playId: typeof item.id === 'string' ? parseInt(item.id) : item.id,
                            playType: item.playType,
                            listIndex: item.listIndex,
                            done: false,
                            moduleContainerId: item.moduleContainerId
                        }));
                        let orderDataLength = orderedData.filter(item => item.playType !== "division").length;

                        if (orderedData.length <= 0) {
                            props.history.push('/liste-formations');
                        }

                        // ******************************************************************
                        // CUSTOM CONDITION TO DISPLAY WEBZONE MODULE IN CONTACT
                        if (!state.userInfos.webzone && ['goBRIOestmonoutildetravailprincipalexterne'].includes(parsedQuery.tag)) {
                            orderDataLength = orderDataLength - 1
                        }
                        // CUSTOM CONDITION TO DISPLAY WEBZONE MODULE IN CONTACT
                        // ******************************************************************

                        setRawCoursesData(courseData);
                        setFilesSource(courseData.files_source);
                        setOrderedCourseSlidesData(orderedData);
                        setOrderedCourseSlidesLength(orderDataLength);
                        setContinuousMode(isCourseContinuous);
                        setRequestMeetingMode(isRequestMeeting);
                        setMeetingRequired(isMeetingRequired);
                        setChatBotAvailable(courseData.chatBotActivated);
                        setChatBotQuestions(courseData.chatBotQuestions);
                        setChatBotEmailRecipients(courseData.chatBotEmailRecipients);
                        setBannerFullHeight(courseData.banner_full_height);


                        /***************SET OR RETRIEVE PROGRESSION LAYOUT*******************/
                        if (savedProgression.length === 0) {
                            setUserCourseProgression({
                                [parsedQuery.tag]: progressionLayout
                            });
                        } else {

                            let target = progressionLayout;
                            let source = savedProgression.filter(sourceItem => target.find(targetItem => targetItem.playId.toString() === sourceItem.playId.toString() && targetItem.playType === sourceItem.playType));

                            mergeByProperty(target, source, ['playId', 'playType']);

                            target = target.map((old, i) => ({ ...old, listIndex: i }));

                            setUserCourseProgression({
                                [parsedQuery.tag]: target
                            });

                            let prevSlideTarget, index = target.length - 1;
                            for (; index >= 0; index--) {
                                if (target[index].done) {
                                    prevSlideTarget = target[index];
                                    setLastSlideDone(prevSlideTarget['listIndex'])
                                    break;
                                }
                            }

                            setProgressPercentage(Math.ceil(target.filter(item => item.done === true && item.playType !== 'division').length * 100 / orderDataLength));
                        }

                        /***************ASSIGN COURSE TO STUDENT PROFILE IF COMING FROM DEEP URL*******************/
                        const coursesTypeAccess = state.userInfos.coursesTypeAccess ? JSON.parse(state.userInfos.coursesTypeAccess) : { coursesType: [] }
                        let userWithCourseCTypesCompare = coursesTypeAccess.coursesType.filter(cType => courseTypes.includes(cType) || cType === parsedQuery.tag)

                        if (userWithCourseCTypesCompare.length === 0) {
                            if (
                                (state.userInfos.isInternal && courseData.deeplink_access_internal) ||
                                (!state.userInfos.isInternal && courseData.deeplink_access_external) ||
                                userAccessCourseTypes.includes('Tout')
                            ) {
                                addCourseToStudent({
                                    variables: {
                                        id: state.userInfos.id,
                                        coursesTypeAccess: JSON.stringify({
                                            coursesType: [
                                                ...coursesTypeAccess.coursesType,
                                                parsedQuery.tag
                                            ]
                                        })
                                    }
                                })
                            } else {
                                props.history.push('/liste-formations');
                            }

                        }

                        setLoadingData(false)

                        /**************SET COURSE TEAM SELECTED FROM COOKIE********************/
                        if (teamsFiltersCourseTags.includes(courseData.course_tag)) {
                            let courseTeamSelected = localStorage.getItem('courseTeamSelected');

                            if (courseTeamSelected && teamsFilters[courseData.course_tag].find(el => el.teamLabel === courseTeamSelected)) {
                                handleFilterModulesByTeamFilters(courseTeamSelected, orderedData);
                            } else {
                                localStorage.removeItem('courseTeamSelected');
                            }
                        }


                    }).catch(err => {
                        console.log('Error fetching results ', err);
                        // throw new Error(err);
                    });
                }
            }
        });


    const { loading: loadingMeetings } = useQuery(ALL_MEETING_RESERVATION,
        {

            skip: !parsedQuery.tag,
            variables: {
                courseName: parsedQuery.tag,
                fromLms: true
            },
            onCompleted(res) {
                console.log('ALL_MEETING_RESERVATION')
                console.log(res)
                if (res.allMeetingReservation) {
                    console.log(res.allMeetingReservation);
                    setAllMeetingReservations(res.allMeetingReservation)
                }
            },
            onError(error) {
                console.log(error)
            }
        });


    const [updateProgression, { loading: updateProgressionLoading }] = useMutation(
        UPDATE_STUDENT_PROGRESSION,
        {
            onCompleted(response) {
                if (response.addOrUpdateStudentProgression) {

                    console.log('updateProgression - onCompleted -----------------------------');

                    let progressionItemReqMeetings = response.addOrUpdateStudentProgression
                    console.log(progressionItemReqMeetings)
                    console.log(backupOrderedCourseSlidesData)
                    addLisIndexToRequestedMeeting(progressionItemReqMeetings, backupOrderedCourseSlidesData)

                    filterAndSetMenuItemsConditions();
                }
            },
            onError(res) {
                console.log(res);
                console.log(res.response);
                console.log(res.graphQLErrors);
                console.log(res.operation);
            }
        }
    );

    const [addTimeCumulatedToStudentProgression] = useMutation(
        ADD_TIME_CUMULATED_TO_STUDENT_PROGRESSION,
        {
            onCompleted(response) {
                if (response.addTimeCumulatedToStudentProgression) {
                    //console.log(progressionItem)
                }
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );

    const [addCoursesAccessLog] = useMutation(
        ADD_COURSES_ACCESS_LOG,
        {
            onCompleted(response) {
                //console.log(response.addCoursesAccessLogs);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );


    const [addCourseToStudent] = useMutation(
        ADD_COURSE_TO_STUDENT,
        {
            onCompleted(response) {
                window.location.reload(false);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );

    //HANDLE BACK PAGE EVENT******************************
    useEffect(() => {
        scrollSlideMenuOnChange();

        if (introPageSkipped === true) {
            window.onpopstate = (event) => {
                event.stopImmediatePropagation();
                handleReturnToIntro();

                window.onpopstate = () => { }
            };
        }

        //What's the most important status in all notifications
        if (allNotifications && allNotifications.length > 0) {
            const status = [];

            allNotifications.forEach((item) => {
                status.push(item.importance)
            })

            if (status.includes('Urgent') && !notificationHasAlreadyOpen) {
                setNotificationStatus('Urgent');
                showModalNotification();
            } else {
                setNotificationStatus('Standard')
            }
        }

        setTimeout(() => {
            setShowChatBotTooltip(prev => { return prev + 1 })
            setTimeout(() => {
                setShowChatBotTooltip(prev => { return prev + 1 })
            }, 5000)
        }, 2000)
        localStorage.setItem('currentCourseTag', parsedQuery.tag);
    }, [introPageSkipped, allNotifications])
    //HANDLE BACK PAGE EVENT******************************



    useEffect(() => {

        //TEMPORARY FIX FOR MISORDERING BUG 
        if (parsedQuery.tag === "goBRIOestmonoutildetravailprincipalexterne" && parsedQuery.meeting) {



            var safeUrl1 = DOMPurify.sanitize("/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + true + '&playId=337&playType=division&meeting=true', { ALLOWED_URI_REGEXP: /^(https?|ftp):/ });
            var safeUrl2 = DOMPurify.sanitize("/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + true + '&playId=339&playType=division&meeting=true', { ALLOWED_URI_REGEXP: /^(https?|ftp):/ });

            if (parseInt(parsedQuery.playId) === 79) {
                window.location.href = safeUrl1;
            }

            if (parseInt(parsedQuery.playId) === 639) {
                window.location.href = safeUrl2;
            }
        }
        //TEMPORARY FIX FOR MISORDERING BUG 

        let thisUserCourseProgression = userCourseProgression[rawCoursesData.course_tag];

        if (parsedQuery.skipIntro === 'true') {
            setIntroPageSkipped(true)
        }

        if (currentPlayContent && !parsedQuery.sondageId) {
            if (currentPlayContent.id) {
                if (introPageSkipped) {
                    window.history.pushState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + introPageSkipped + '&playId=' + currentPlayContent.id + '&playType=' + currentPlayContent.playType);
                } else {
                    window.history.pushState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + introPageSkipped);
                }
            }
        }

        if (thisUserCourseProgression) {

            console.log(orderedCourseSlidesData)
            let filteredProgress = thisUserCourseProgression.filter(item => orderedCourseSlidesData.find(item2 => (item2.id.toString() === item.playId.toString()) && item2.playType === item.playType && item2.playType !== 'division'))

            setProgressPercentage(Math.ceil(filteredProgress.filter(item => item.done === true).length * 100 / orderedCourseSlidesLength))

            filterAndSetMenuItemsConditions();

            /***************HANLDE GO TO SELECTED SLIDE FROM URL OR GO TO FIRST SLIDE NOT DONE*******************/
            // console.log(firstLoad);
            // console.log(filterAndSetMenuItemsConditionsDone);

            if (firstLoad && filterAndSetMenuItemsConditionsDone) {
                let i = 0;
                let notDoneFound = false;

                if (!parsedQuery.playId && !parsedQuery.sondageId) {

                    while (i < thisUserCourseProgression.length && !notDoneFound) {
                        let stopWhile = false

                        if (!thisUserCourseProgression[i]['done']) {
                            stopWhile = true
                        }

                        if (userCourseRequestedMeetings) {
                            if (userCourseRequestedMeetings.filter(el => el.blocked).length > 0) {
                                if (i === userCourseRequestedMeetings.filter(el => el.blocked)[0].listIndex) {
                                    stopWhile = true
                                }
                            }
                        }

                        if (stopWhile) {
                            const selectedSlide = orderedCourseSlidesData.filter(item => thisUserCourseProgression[i].playId.toString() === item.id.toString() && thisUserCourseProgression[i].playType === item.playType)[0];
                            if (selectedSlide) {
                                const sondage = allSondages.find(sondage => sondage.prevModuleRelated === null);
                                handleCurrentPlayChange({
                                    listIndex: selectedSlide.listIndex,
                                    sondage: selectedSlide.listIndex === 0 && sondage ? sondage : null
                                });
                            }

                            notDoneFound = true
                            setFirstLoad(false)
                        }
                        i++;
                    }
                } else {
                    const selectedSlide = orderedCourseSlidesData.find(item => parsedQuery.playId && parsedQuery.playId.toString() === item.id.toString() && parsedQuery.playType === item.playType);
                    console.log('TEST 1')
                    if (selectedSlide) {
                        console.log('TEST 3')
                        const progressionIsExistant = Object.values(thisUserCourseProgression).some(item => item.startDate);

                        handleCurrentPlayChange({
                            ...(parsedQuery.meeting ? {
                                listIndex: progressionIsExistant ? selectedSlide.listIndex : selectedSlide.listIndex - 1,
                                forceDisplayMeetingPage: progressionIsExistant
                            } : {
                                listIndex: selectedSlide.listIndex
                            }),
                        });
                    } else if (parsedQuery.sondageId) {
                        const sondage = allSondages.find(sondage => sondage.id === parsedQuery.sondageId);

                        if (sondage) {
                            handleCurrentPlayChange({ sondage });
                        } else {
                            redirectToFormation();
                        }
                    } else {
                        redirectToFormation();
                    }

                    function redirectToFormation() {
                        window.location.href = "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + false;
                    }

                    setFirstLoad(false)
                }
            }
        }


        /***************RESET MODULE STATE FOR REFRESH PURPOSES*******************/
        //console.log(resetModule);
        if (resetModule) {
            setTimeout(() => {
                setResetModule(false)
            }, 600)
        }

    }, [...(currentPlayContent ? [currentPlayContent.listIndex, currentPlayContent.id] : []), userCourseProgression, loadingProgression, userCourseRequestedMeetings, filterAndSetMenuItemsConditionsDone, resetModule, allSondages])


    useEffect(() => {

        /***************SET PREGRESSION AS DONE FOR THESE PARTICULAR MODULE WHEN ACCESS*******************/
        if (currentPlayContent.id && introPageSkipped && currentRunningSondage === null) {
            if (['start', 'end', 'division', 'documents', 'html'].includes(currentPlayContent.playType) && !currentPlayContent.completionOnNextButtonClick) {
                updateCurrentUserProgression(true, false, true, true);
            } else {
                updateCurrentUserProgression(false, false, false, false);
            }

            // 30 seconds timer to add time cumulated in progression ***********************
            let timeCumulate = 0;
            let timeCumulateIntevalSeconds = 60;

            const timerInterval = setInterval(() => {
                timeCumulate += 1

                if (timeCumulate > 60 && timeCumulate % timeCumulateIntevalSeconds === 0) {
                    addTimeCumulatedToStudentProgression({
                        variables: {
                            studentId: state.userInfos.id,
                            courseName: rawCoursesData['course_tag'],
                            playId: typeof currentPlayContent.id === 'string' ? parseInt(currentPlayContent.id) : currentPlayContent.id,
                            secondsToAdd: timeCumulateIntevalSeconds,
                        }
                    })
                }
            }, 1000);
            return () => clearInterval(timerInterval);
        }


    }, [...(currentPlayContent ? [currentPlayContent.listIndex, currentPlayContent.id, introPageSkipped] : [])])




    const addLisIndexToRequestedMeeting = (requestedMeetings, orderedSlidesData) => {
        if (requestedMeetings) {
            console.log(requestedMeetings)
            let reqMeetings = JSON.parse(requestedMeetings);

            console.log(typeof reqMeetings)

            reqMeetings.map(meeting => {

                let moduleListIndex = orderedSlidesData.find(el => el.id.toString() === meeting.courseModuleBlocked.toString() && el.playType === meeting.courseModuleBlockedType)

                if (moduleListIndex) {
                    meeting.listIndex = moduleListIndex.listIndex
                }
            })

            reqMeetings.sort((a, b) => a.listIndex - b.listIndex);

            console.log('addLisIndexToRequestedMeeting -------');
            console.log(reqMeetings);

            setUserCourseRequestedMeetings(reqMeetings)
        }
    }

    const filterAndSetMenuItemsConditions = () => {
        setOrderedCourseSlidesData(prev => {

            prev.map((el, i) => {

                let thisUserProgression = userCourseProgression[rawCoursesData.course_tag];
                let thisSlideStartDate = null;
                let thisSlideDone = false;
                let slideBeforeDone = "false";


                if (thisUserProgression) {
                    let thisSlideP = thisUserProgression.filter(progressEl => progressEl.playId.toString() === el.id.toString() && progressEl.playType === el.playType)[0]

                    if (thisSlideP) {
                        //console.log(thisSlideP)
                        thisSlideDone = thisSlideP.done;
                        thisSlideStartDate = thisSlideP.startDate ? thisSlideP.startDate : null;
                        if (i !== 0) {
                            let thisSlideBeforeP = thisUserProgression.filter(progressEl => progressEl.playId.toString() === orderedCourseSlidesData[i - 1].id.toString() && progressEl.playType === orderedCourseSlidesData[i - 1].playType)[0]
                            if (thisSlideBeforeP) {
                                slideBeforeDone = thisSlideBeforeP.done.toString();
                            }
                        }
                    }
                }

                let meetingRequestedLockedCondition = () => {
                    let locked = false;
                    // if (continuousMode && requestMeetingMode && userCourseRequestedMeetings) {
                    if (requestMeetingMode && userCourseRequestedMeetings) {
                        let reqMeeting = userCourseRequestedMeetings.find(meeting => meeting.courseModuleBlocked.toString() === el.id.toString() && meeting.courseModuleBlockedType === el.playType)

                        if (reqMeeting) {
                            console.log(reqMeeting)
                        }

                        if (reqMeeting && reqMeeting.blocked) {
                            locked = meetingsAvailableForThisModule(el.listIndex).length > 0
                        }

                    }
                    return locked
                }

                let meetingRequestAvailable = () => {

                    //**************************************************************************************************************** */
                    // before update - SHOW RESERVATION MEETING BUTTON BY DEFAULT EVEN IF THE MODULE RELATED WAS NOT COMPLETED
                    //**************************************************************************************************************** */
                    // let available = false;

                    // if (requestMeetingMode && userCourseRequestedMeetings) {
                    //     let reqMeeting = userCourseRequestedMeetings.find(meeting => meeting.courseModuleBlocked.toString() === el.id.toString() && meeting.courseModuleBlockedType === el.playType)

                    //     if (reqMeeting) {
                    //         available = true
                    //     }
                    // }
                    // return available



                    //**************************************************************************************************************** */
                    // after update - SHOW RESERVATION MEETING BUTTON BY DEFAULT EVEN IF THE MODULE RELATED WAS NOT COMPLETED
                    //**************************************************************************************************************** */
                    return meetingsAvailableForThisModule(el.listIndex).length > 0
                }

                let isAfterFirstReqMeetingBlocked = false;

                // if (continuousMode && requestMeetingMode && userCourseRequestedMeetings) {
                if (requestMeetingMode && userCourseRequestedMeetings) {
                    if (userCourseRequestedMeetings.filter(el => el.blocked)[0]) {
                        //console.log(el.listIndex);
                        //console.log(userCourseRequestedMeetings.filter(el => el.blocked)[0]);
                        isAfterFirstReqMeetingBlocked = el.listIndex > userCourseRequestedMeetings.filter(el => el.blocked)[0].listIndex;
                    }
                }

                let continuousLockedCondition = (continuousMode && ((slideBeforeDone === 'false' && thisSlideDone === false && i > lastSlideDone) || isAfterFirstReqMeetingBlocked))

                let titleTime = () => {

                    let timeByType = [
                        {
                            type: ['video'],
                            time: el.time != null && el.time >= 1 ? fancyTimeFormat(el.time * 60) : '1 min +'
                        },
                        {
                            type: ['autoevaluation1', 'exercice', 'dndtext', 'dndmultiple', 'dndimages', 'calculation', 'rafale', 'exerciceopenquestion', 'exercicemultiplechoices'],
                            time: el.time != null && el.time >= 1 ? fancyTimeFormat(el.time * 60) : '15 min +'
                        },
                        {
                            type: ['exam', 'exammultiplechoice'],
                            time: el.duration != null && el.duration >= 1 ? fancyTimeFormat(el.duration * 60) : '30 min +'
                        },
                        {
                            type: ['scorm'],
                            time: el.time != null && el.time >= 1 ? fancyTimeFormat(el.time * 60) : '30 min +'
                        },
                        {
                            type: ['html', 'documents'],
                            time: el.time != null && el.time >= 1 ? fancyTimeFormat(el.time * 60) : '5 min +'
                        },
                    ]

                    let tTypeExist = timeByType.find(tType => tType.type.includes(el.playType));

                    return tTypeExist ? tTypeExist.time : null
                }

                el.thisSlideDone = thisSlideDone;
                el.thisSlideStartDate = thisSlideStartDate;
                el.blockedByProgression = continuousLockedCondition;
                el.blockedByReqMeeting = meetingRequestedLockedCondition();
                el.meetingAvailable = meetingRequestAvailable();
                el.titleTime = titleTime()

            })

            console.log(prev);

            return prev
        })

        setFilterAndSetMenuItemsConditionsDone(true)
    }

    const meetingsAvailableForThisModule = (thisModuleListIndex) => allMeetingReservations.filter(meetingEl => {
        const courseAvailabilityParsed = JSON.parse(meetingEl.courseAvailability);
        // console.log('courseAvailabilityParsed', courseAvailabilityParsed)
        // let courseModuleStartOrder = rawCoursesData.courses_modules.find(el => el.modules_id.id.toString() === courseAvailabilityParsed.courseModuleStart).sort;
        // let courseModuleEndOrder = rawCoursesData.courses_modules.find(el => el.modules_id.id.toString() === courseAvailabilityParsed.courseModuleEnd).sort;
        let courseModulesOrders = rawCoursesData.courses_modules.filter(el => courseAvailabilityParsed.courseModules.includes(el.modules_id.id.toString())).map(el => el.sort + 1);


        let thisCourseModuleEndOrder = thisModuleListIndex + 1;


        // console.log('courseModulesOrders', courseModulesOrders);
        // console.log('thisCourseModuleEndOrder', thisCourseModuleEndOrder);

        if (courseModulesOrders.includes(thisCourseModuleEndOrder)) {

            // console.log('meetingsAvailableForThisModule')
            // console.log(rawCoursesData.courses_modules)
            // console.log('meetingsAvailableForThisModule')
            // console.log(courseAvailabilityParsed)
            // console.log(courseModulesOrders)
            return true
        } else {
            return false
        }
        // if (thisCourseModuleEndOrder >= courseModuleStartOrder && thisCourseModuleEndOrder <= courseModuleEndOrder) {
        //     return true
        // } else {
        //     return false
        // }
    })



    const handleNextSlide = (e) => {
        if (currentPlayContent.listIndex < orderedCourseSlidesData.length - 1) {
            console.log('handleNextSlide -  *********************************************************')
            filterAndSetMenuItemsConditions();
            console.log(userCourseRequestedMeetings)

            let sondageAvailable = allSondages.find(sondage =>
                sondage.status === 'published' &&
                sondage.prevModuleRelated &&
                sondage.prevModuleRelated.toString() === currentPlayContent.moduleContainerId &&
                !isSondageLocked(sondage)
            );

            handleCurrentPlayChange({
                listIndex: currentPlayContent.listIndex + 1,
                nextSlideEvent: true,
                sondage: sondageAvailable
            })

            scrollSlideMenuOnChange();
            refetchAllNotification();
        }
    };

    const handleNextSlideFromSondage = (e) => {
        let currentListIndex = currentRunningSondage.prevModuleRelated ? orderedCourseSlidesData.find(el => el.moduleContainerId.toString() === currentRunningSondage.prevModuleRelated.toString()).listIndex : null;

        if (currentListIndex < orderedCourseSlidesData.length - 1) {
            console.log('handleNextSlideFromSondage -  *********************************************************')
            console.log(currentListIndex)
            filterAndSetMenuItemsConditions();
            console.log(userCourseRequestedMeetings)
            handleCurrentPlayChange({ listIndex: currentListIndex === null ? 0 : currentListIndex + 1, nextSlideEvent: true })
            setCurrentRunningSondage(null);
            setCurrentRunningSondageAnswers([]);
            scrollSlideMenuOnChange();
            refetchAllNotification();
            refetchSondagesAnswers();
        }
    };

    const updateCurrentUserProgression = (resetThisModule = true, failedAndRequestMeeting = false, done = true, instantDone = false) => {


        setUserCourseProgression(old => {
            let courseName = rawCoursesData.course_tag
            let newProgress = old;

            console.log('setUserCourseProgression');
            console.log(newProgress);

            if (old[courseName] && currentPlayContent.id) {
                console.log(currentPlayContent)
                let indexOfCurrentPlay = newProgress[courseName].findIndex(el => el.playId.toString() === currentPlayContent.id.toString() && el.playType === currentPlayContent.playType);
                let playReqMeetingBlocked = newProgress[courseName][indexOfCurrentPlay + 1] ? newProgress[courseName][indexOfCurrentPlay + 1] : null;

                // console.log(playReqMeetingBlocked);
                // console.log(newProgress[courseName][indexOfCurrentPlay]);
                // console.log(newProgress[courseName][indexOfCurrentPlay + 1]);

                newProgress[courseName][indexOfCurrentPlay].done = newProgress[courseName][indexOfCurrentPlay].done ? newProgress[courseName][indexOfCurrentPlay].done : done;

                if (!teamsFiltersCourseTags.includes(courseName) || !contactTeamSelected) {
                    newProgress[courseName][indexOfCurrentPlay].listIndex = currentPlayContent.listIndex;
                }


                if (instantDone) {
                    if (done) {
                        newProgress[courseName][indexOfCurrentPlay].startDate = moment(new Date()).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss");
                        newProgress[courseName][indexOfCurrentPlay].completionDate = moment(new Date()).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss");
                        failedAndRequestMeeting = true;
                    }
                } else {
                    if (done) {
                        newProgress[courseName][indexOfCurrentPlay].completionDate = moment(new Date()).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss");
                        failedAndRequestMeeting = true;
                    } else {
                        newProgress[courseName][indexOfCurrentPlay].startDate = moment(new Date()).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss");
                    }
                }

                if (resetThisModule) {
                    setResetModule(true)
                }

                if (!updateProgressionLoading) {
                    updateProgression({
                        variables: {
                            studentId: state.userInfos.id,
                            studentName: state.userInfos.firstName + " " + state.userInfos.lastName,
                            courseName: courseName,
                            progressionJson: JSON.stringify(newProgress[courseName]),
                            ...(failedAndRequestMeeting && playReqMeetingBlocked && requestMeetingMode && meetingsAvailableForThisModule(indexOfCurrentPlay + 1).length > 0 ? { requestedMeetingModule: JSON.stringify({ id: playReqMeetingBlocked.playId, type: playReqMeetingBlocked.playType }) } : {})
                        }
                    });
                }
            }
            return newProgress
        })
        refetchAllNotification();

    }

    const handleSlideDoneFromVideo = (e) => {
        setVideoDone(true);
        setReplayVideoAvailable(true);
    };

    const scrollSlideMenuOnChange = () => {
        let countVoid = 0

        const interval = setInterval(() => {
            const currentEl = document.querySelector('.current-play-el')
            if (currentEl) {
                document.querySelector('.play-list-menu > ul').scrollTop = currentEl.offsetTop - (currentEl.offsetHeight * 2 + 50);
                clearInterval(interval)
            }
            if (countVoid >= 10) {
                clearInterval(interval)
            }
            countVoid++
        }, 200);

    }

    const toggleSearchMenuInput = () => {
        setDisplaySearchMenu(prev => !prev);
        scrollSlideMenuOnSearchChange('')
    }

    const scrollSlideMenuOnSearchChange = (searchText) => {

        if (searchText && searchText.trim().length > 0) {
            let countVoid = 0
            const allMenuTitleContainingSearch = [...document.querySelectorAll('.play-el')].filter(div => div.querySelector('.list-title-text').innerHTML.toLowerCase().includes(searchText.toLowerCase()));
            const notSearchedMenuItems = [...document.querySelectorAll('.play-el')].filter(div => !div.querySelector('.list-title-text').innerHTML.toLowerCase().includes(searchText.toLowerCase()));

            if (allMenuTitleContainingSearch.length > 0) {
                const interval = setInterval(() => {
                    const seletedEl = allMenuTitleContainingSearch[0]
                    if (seletedEl) {
                        document.querySelector('.play-list-menu > ul').scrollTop = seletedEl.offsetTop - (seletedEl.offsetHeight * 2 + 50);

                        clearInterval(interval)
                    }
                    if (countVoid >= 10) {
                        clearInterval(interval)
                    }
                    countVoid++
                }, 200);

                allMenuTitleContainingSearch.map(selectSearch => {
                    selectSearch.querySelector('.list-title-text').classList.add("list-title-text-searched")
                })
            }

            notSearchedMenuItems.map(notSearch => {
                notSearch.querySelector('.list-title-text').classList.remove("list-title-text-searched")
            })
        } else {
            [...document.querySelectorAll('.play-el')].map(notSearch => {
                notSearch.querySelector('.list-title-text').classList.remove("list-title-text-searched")
            })
        }

    }

    const handleCurrentPlayChange = ({ listIndex, forceDisplayMeetingPage = false, nextSlideEvent = false, sondage = null }) => {

        if (sondage) {
            if (introPageSkipped) window.history.pushState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + true + '&sondageId=' + sondage.id);

            setCurrentRunningSondage(sondage)
            setCurrentRunningSondageAnswers(allSondagesAnswers.filter(el => el.sondageId.toString() === sondage.id))
            handleCloseDeskptoMenu()
            setResetModule(true);
        } else {
            if (introPageSkipped) window.history.pushState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + true);
            setCurrentRunningSondage(null);
            setCurrentRunningSondageAnswers([])

            let selectedSlide = orderedCourseSlidesData.filter(item => item.listIndex === listIndex)[0];
            let isDisplayMeetingSection = false;

            // ******************************************************************
            // Display additional information modal
            if (selectedSlide.additionalInformation) {
                handleShowAdditionalInformationModal(selectedSlide.additionalInformation)
            }
            // ******************************************************************

            // ******************************************************************
            // CUSTOM CONDITION TO DISPLAY WEBZONE MODULE IN CONTACT
            if (!state.userInfos.webzone && ['goBRIOestmonoutildetravailprincipalexterne'].includes(parsedQuery.tag) && `${selectedSlide.id}_${selectedSlide.playType}` === '264_documents') {
                selectedSlide = orderedCourseSlidesData.filter(item => item.listIndex === listIndex + 1)[0];
            }
            // CUSTOM CONDITION TO DISPLAY WEBZONE MODULE IN CONTACT
            // ******************************************************************

            if (selectedSlide.blockedByProgression) {
                let filteredNotBlockedByProgression = orderedCourseSlidesData.filter(item => !item.blockedByProgression)
                selectedSlide = filteredNotBlockedByProgression[filteredNotBlockedByProgression.length - 1]
            }


            //**************************************************************************************************************** */
            //before update - SHOW RESERVATION MEETING BUTTON BY DEFAULT EVEN IF THE MODULE RELATED WAS NOT COMPLETED
            //**************************************************************************************************************** */
            // if (userCourseRequestedMeetings && requestMeetingMode) {
            //     let reqMeeting = userCourseRequestedMeetings.find(reqMeeting => reqMeeting.courseModuleBlocked.toString() === selectedSlide.id.toString() && reqMeeting.courseModuleBlockedType === selectedSlide.playType)
            //     if ((reqMeeting && meetingsAvailableForThisModule(reqMeeting.listIndex).length > 0) && ((reqMeeting.blocked && parsedQuery.tag !== "RencontreIntactambassadeursContactAP") || forceDisplayMeetingPage || nextSlideEvent)) {
            //         setResetModule(true);
            //         setTimeout(() => {
            //             setResetModule(false);
            //         }, 1000)
            //         isDisplayMeetingSection = true;
            //         window.history.pushState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + true + '&playId=' + selectedSlide.id + '&playType=' + selectedSlide.playType);
            //         setSelectedCourseReqMeeting(reqMeeting)
            //         setMenuHidden(false);
            //         setCurrentPlayContent({ listIndex: reqMeeting.listIndex, typeToSendMeeting: selectedSlide.playType });
            //         console.log(reqMeeting.listIndex)
            //     }

            // }


            //**************************************************************************************************************** */
            // after update - SHOW RESERVATION MEETING BUTTON BY DEFAULT EVEN IF THE MODULE RELATED WAS NOT COMPLETED
            //**************************************************************************************************************** */
            if (requestMeetingMode) {
                console.log(userCourseRequestedMeetings)
                if ((forceDisplayMeetingPage || nextSlideEvent)) {
                    let reqMeeting = userCourseRequestedMeetings ? userCourseRequestedMeetings.find(reqMeeting => reqMeeting.courseModuleBlocked.toString() === selectedSlide.id.toString() && reqMeeting.courseModuleBlockedType === selectedSlide.playType) : null
                    console.log(reqMeeting)
                    setResetModule(true);
                    setTimeout(() => {
                        setResetModule(false);
                    }, 1000)
                    isDisplayMeetingSection = true;
                    window.history.pushState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + true + '&playId=' + selectedSlide.id + '&playType=' + selectedSlide.playType);
                    setSelectedCourseReqMeeting(reqMeeting ? reqMeeting : { courseModuleBlocked: selectedSlide.id, courseModuleBlockedType: selectedSlide.playType, blocked: false, listIndex: listIndex })
                    setMenuHidden(false);
                    setCurrentPlayContent({ listIndex: listIndex, typeToSendMeeting: selectedSlide.playType });
                    // console.log(reqMeeting.listIndex)
                }

            }

            if (!isDisplayMeetingSection) {
                handleCloseDeskptoMenu()
                if (['scorm', 'video', 'dndimages', 'dndmultiple', 'dndtext', 'exam', 'exammultiplechoice', 'exercice', 'exerciceopenquestion', 'exercicemultiplechoices', 'calculation', 'rafale'].includes(selectedSlide['playType'])) {
                    if (selectedSlide.question) {
                        if (selectedSlide.question && selectedSlide.question[0].sort === null) {
                            selectedSlide.question = selectedSlide.question.map((item, index) => {
                                return ({ ...item, sort: index + 1 })
                            });
                        }
                    }

                }

                setCurrentPlayContent(selectedSlide);
                setVideoDone(false);
                setReplayVideoAvailable(false);
                setResetModule(true);
                handleMobileClosePlayListMenu();
                setStartSlideFromIntro(false);
            }

            setDisplayMeetingSection(isDisplayMeetingSection);
        }
    };

    const handleCloseDeskptoMenu = () => {
        setMenuHidden(true)
        setShowMenuBtnTooltip(true)
        setTimeout(() => {
            setShowMenuBtnTooltip(false)
        }, 2000)
    }

    const handleMobileClosePlayListMenu = e => {
        setPlayListMobileMenuClass('play-list-menu-mobile');
        setPlayListMobileMenuBtn(faList);
    };

    const handleMobileOpenClosePlayListMenu = e => {
        if (playListMobileMenuClass.indexOf('play-list-menu-mobile-open') !== -1) {
            setPlayListMobileMenuClass('play-list-menu-mobile');
            setPlayListMobileMenuBtn(faList);
        } else {
            setPlayListMobileMenuClass('play-list-menu-mobile play-list-menu-mobile-open');
            setPlayListMobileMenuBtn(faTimes);
        }
    };

    const handleSkipIntro = () => {
        setIntroPageSkipped(true)
        scrollSlideMenuOnChange();
    }

    const handleSkipIntroAndGoToSlide = (listIndex) => {
        setIntroPageSkipped(true);
        handleCurrentPlayChange({ listIndex })
        scrollSlideMenuOnChange();
    }

    const handleReturnToIntro = () => {
        window.history.replaceState("", "", "/formation/?tag=" + parsedQuery.tag + '&skipIntro=' + false);
        setIntroPageSkipped(false);
        setStartSlideFromIntro(true);
        setMenuHidden(false);
    }

    const handleFilterModulesByTeamFilters = (teamLabel, backupOrderedCourseSlidesData) => {
        console.log('handleFilterModulesByTeamFilters', orderedCourseSlidesData);
        console.log('backupOrderedCourseSlidesData', backupOrderedCourseSlidesData);
        console.log('teamLabel', teamLabel);

        setContactTeamSelected(teamLabel);

        if (teamLabel) {
            let thisTeamModules = teamsFilters[parsedQuery.tag].find(element => element.teamLabel === teamLabel).modules;

            setOrderedCourseSlidesData(prev => {

                let oldModules = [...backupOrderedCourseSlidesData]

                let filteredModules = oldModules.filter(module => thisTeamModules.includes(module.id + '_' + module.playType))

                filteredModules.forEach((module, index) => {
                    module.listIndex = index
                })

                let orderDataLength = filteredModules.filter(item => item.playType !== "division").length;

                setOrderedCourseSlidesLength(orderDataLength);

                console.log('filteredModules', filteredModules);

                return filteredModules
            });

            localStorage.setItem('courseTeamSelected', teamLabel);
        } else {
            setOrderedCourseSlidesData(backupOrderedCourseSlidesData);

            let orderDataLength = backupOrderedCourseSlidesData.filter(item => item.playType !== "division").length;

            setOrderedCourseSlidesLength(orderDataLength);

            localStorage.removeItem('courseTeamSelected');
        }


        setResetModule(true);
    }

    const verifyModulesRequirementsConditionsBlocked = (module) => {

        if (modulesRequirementsConditions) {

            const modulesRequired = modulesRequirementsConditions.filter(el => el.module_blocked.toString() === module.moduleContainerId).map(el => el.modules_required).flat();

            return userCourseProgression[rawCoursesData.course_tag].filter(el => modulesRequired.includes(el.moduleContainerId) && !el.done).length > 0
        } else {
            return false
        }
    };


    const modulesRequirementsConditionsBlockedListIndexes = (module) => {

        if (modulesRequirementsConditions) {

            const modulesRequired = modulesRequirementsConditions.filter(el => el.module_blocked.toString() === module.moduleContainerId).map(el => el.modules_required).flat();

            return orderedCourseSlidesData.filter(el => modulesRequired.includes(el.moduleContainerId)).map(el => el.listIndex + 1).join(', ');
        } else {
            return null
        }
    }


    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseAdditionalInformationModal = () => {
        setAdditionalInformationModalContent(null);
        setShowAdditionalInformationModal(false)
    };
    const handleShowAdditionalInformationModal = (content) => {
        setAdditionalInformationModalContent(content);
        setShowAdditionalInformationModal(true)
    };
    const handleCloseModalContactFiltersTeam = () => setShowModalContactFiltersTeam(false);
    const handleShowModalContactFiltersTeam = () => setShowModalContactFiltersTeam(true);

    const documentsAndNotesManual = (
        <DocumentsAndNotesManual
            studentGuideButtonDisabled={rawCoursesData.studentGuideButtonDisabled}
            courseData={orderedCourseSlidesData}
            courseTitle={rawCoursesData['course_title']}
            courseDocuments={rawCoursesData['documents']} />
    )

    const handleOpenCloseChatbot = () => {
        if (minimizedChatBox === false) {
            setDisplayChatBotBox(prev => !prev)
        } else {
            setMinMaxChatBotBox(prev => !prev)
        }
    }

    const handleMinMaxChatBotBox = () => {
        setMinMaxChatBotBox(prev => !prev)
    }

    const customDisplayOptionnalLabel = (module) => {

        const conditionsCourseTag = [
            "JesoutienslesgensquiutilisentgoBRIOauquotidien",
            "JaidesbesoinsspcifiquesdansgoBRIO",
            "JesoutienslesgensquiutilisentgoBRIOauquotidienexterne"
        ];

        const conditionsModules = [
            "1120_scorm",
            "1121_scorm",
            "1122_scorm",
            "1143_scorm",
            "1144_scorm",
            "1145_scorm",
            "1049_scorm",
            "1146_scorm",
            "1147_scorm",
            "1148_scorm",
            "1050_scorm",
            "1051_scorm",
            "1052_scorm",
            "1053_scorm",
            "1123_scorm",
            "1124_scorm",
            "1125_scorm",
            "1126_scorm",
            "1128_scorm",
            "1127_scorm",
            "1129_scorm",
            "1150_scorm",
            "1151_scorm",
            "1152_scorm",
        ]


        if (conditionsCourseTag.includes(rawCoursesData.course_tag) && conditionsModules.includes(`${module.id}_${module.playType}`)) {
            return "(Optionnelle)"
        } else {
            return ""
        }

    }


    const mobileNotCompatible = <div style={{ textAlign: 'center', fontSize: 20, padding: 15, color: 'red' }}>Veuillez faire cette activité sur un ordinateur.</div>;

    const menuHiddenContentClass = (menuHidden ? 'content-wide' : '')

    if (loadingData || loadingProgression || !currentPlayContent) return (<div className="fallback-black-loader course-play-loader"><MoonLoader color={'#cd1236'} /></div>);


    const isSondageLocked = (sondage, previousModuleDone) => {


        const restrictionVerification = (accessRestriction) => {
            if (accessRestriction === 'prevModuleCompletion') return !previousModuleDone;

            if (!accessRestriction || accessRestriction === 'noRestriction') {
                return false;
            }
        }


        const reportingByCohortes = sondage.reportingByCohortes ? JSON.parse(sondage.reportingByCohortes) : [];
        const reportingGeneral = sondage.reportingGeneral ? JSON.parse(sondage.reportingGeneral) : {};


        const now = moment(new Date);

        const studentCohortesId = state.userInfos.cohortes.reduce((acc, cohorte) => {
            if (cohorte.course === rawCoursesData.course_tag && sondage.reportingByCohortes.includes(cohorte.cohorteID)) {
                acc.push(cohorte.cohorteID);
            }
            return acc;
        }, []);


        let lockDate = null;


        console.log('studentCohortesId', studentCohortesId)

        if (studentCohortesId && studentCohortesId.length > 0) {
            let cohorLock = null;
            let pastDateFound = false;

            reportingByCohortes.some((reporting) => {
                if (reporting.cohortes.some(cohorte => studentCohortesId.includes(cohorte))) {
                    if (reporting.studentSendDate) {
                        const studentSendDate = moment(reporting.studentSendDate);
                        if (reporting.accessRestriction === 'dateAssigned') {
                            if (now.isBefore(studentSendDate) && !pastDateFound) {
                                cohorLock = cohorLock === null || studentSendDate.isBefore(cohorLock) ? studentSendDate : cohorLock;
                            } else if (now.isSameOrAfter(studentSendDate)) {
                                cohorLock = null;
                                pastDateFound = true;
                                return true;
                            }
                        }
                    }
                }
                return false;
            });

            lockDate = cohorLock;
        } else {
            if (reportingGeneral.accessRestriction !== 'dateAssigned') {
                return restrictionVerification(reportingGeneral.accessRestriction);
            } else if (now.isBefore(moment(reportingGeneral.studentSendDate))) {
                lockDate = moment(reportingGeneral.studentSendDate);
            }

        }

        return lockDate;
    }

    const sondageMenuListItem = ({
        currentRunningSondage,
        sondage,
        index,
        previousModuleDone
    }) => {

        const isLockedDate = isSondageLocked(sondage, previousModuleDone);

        const isCurrentSlide = currentRunningSondage && currentRunningSondage.id === sondage.id

        const isSlideDone = allSondagesAnswers.some(sondageAnswer => sondageAnswer.sondageId.toString() === sondage.id);

        const getIcon = () => {
            if (isSlideDone) {
                return <FontAwesomeIcon className={'done-check'} icon={faCheckCircle} />;
            }

            if (isLockedDate) {
                return <FontAwesomeIcon className={'not-done-lock'} icon={faLock} />;
            }

            return isCurrentSlide ? <div className={'current-check'}></div> : <div className={'not-done-check'}></div>;
        };

        if (!introPageSkipped) {
            var safeUrl = DOMPurify.sanitize('/formation/?tag=' + parsedQuery.tag + '&skipIntro=true&sondageId=' + sondage.id, { ALLOWED_URI_REGEXP: /^(https?|ftp):/ });

            return (
                <a href={!isLockedDate ? safeUrl : null} className={'list-item-sondage-link'}>
                    <div className={'list-item list-item-sondage'}>
                        <div className={'list-title'}>
                            <div className={'done-cnt'}>
                                {
                                    !isLockedDate && (
                                        isSlideDone ? <FontAwesomeIcon className={'done-check'} icon={faCheckCircle} /> : <div className={'not-done-check'}></div>
                                    )
                                }

                                {isLockedDate && <FontAwesomeIcon className={'not-done-lock'} icon={faLock} />}

                            </div>

                            <p>
                                {sondage.title}
                            </p>
                        </div>

                        <div className={'list-infos'}>
                            <div className={'list-type-icon'}>
                                <FontAwesomeIcon icon={faStar} />
                            </div>

                            <div className={'list-time'}>
                                <FontAwesomeIcon icon={faClock} />
                                <span>{fancyTimeFormat(sondage.time * 60)}</span>
                            </div>
                        </div>
                    </div>
                </a >
            )
        }

        return (
            <li
                key={index + 'sondage'}
                className={isCurrentSlide ? 'current-play-el play-el' : 'play-el'}
                done={isSlideDone ? 'true' : 'false'}
                style={isLockedDate ? { opacity: 0.7 } : {}}
                onClick={() => !isLockedDate ? handleCurrentPlayChange({ sondage }) : console.log('sondage locked')}>
                {/* <span className={'slide-number'}>*</span> */}

                {
                    getIcon()
                }

                <span className={'list-title'}>
                    <FontAwesomeIcon className={'list-type-icon'} icon={faStar} />
                    <span className={'list-title-text'}>{sondage.title}</span>
                    <span className={'list-title-time'}>{'(' + fancyTimeFormat(sondage.time * 60) + ')'}</span>

                    {
                        (isLockedDate && moment(isLockedDate).isValid()) && (
                            <span className={'list-title-prerequis-text'}>
                                {state.lang === 'fr' ? 'Disponible le' : 'Available'}
                                <br />
                                {moment(isLockedDate).format("dddd, Do MMMM  YYYY")}
                            </span>
                        )
                    }

                </span>
            </li>
        )
    }

    return (
        <div className={'course-play-page'}>
            <h1 className='d-none'>{rawCoursesData['course_title']}</h1>

            {
                chatBotAvailable && displayChatBotBox ?
                    <div className={"chatbot-box" + " " + (minimizedChatBox ? "chatBot-is-min" : "chatBot-is-max")}>
                        <ChatBotIcite
                            chatBotQuestions={chatBotQuestions}
                            chatBotEmailRecipients={chatBotEmailRecipients}
                            courseName={parsedQuery.tag}
                            lang={state.lang}
                            userInfos={state.userInfos}
                            handleMinMaxChatBotBox={handleMinMaxChatBotBox}
                            handleOpenCloseChatbot={handleOpenCloseChatbot} />
                    </div> : null
            }

            {
                chatBotAvailable ?
                    <TooltipAntd placement="topLeft" title={"Question ?"} open={showChatBotTooltip === 1}>
                        <div className={"chatbot-icon-cnt"} onClick={handleOpenCloseChatbot}>
                            {minimizedChatBox === true ?
                                <div className={"chatbot-min-icon"} >
                                    <FontAwesomeIcon icon={faCommentDots} />
                                </div>
                                : ""
                            }
                            <FontAwesomeIcon icon={faRobot} className={"chatbot-icon"} />
                        </div>
                    </TooltipAntd> : null
            }

            <div>

            </div>


            <FontAwesomeIcon className={'mobile-play-list-btn ' + (!introPageSkipped ? 'd-none' : '')} icon={playListMobileMenuBtn} onClick={handleMobileOpenClosePlayListMenu} />

            <div ref={targetMenuBtnTooltip} className={'open-hidden-menu-btn ' + (menuHidden && introPageSkipped ? 'open-hidden-menu-btn-visible' : '')} onClick={() => (setMenuHidden(false))}>
                <FontAwesomeIcon icon={faList} />
            </div>

            <Overlay target={targetMenuBtnTooltip.current} show={showMenuBtnTooltip && introPageSkipped} placement="right">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {t('coursePlayClickOnIcon')}

                    </Tooltip>
                )}
            </Overlay>

            {/***************************************************/}
            {/*REMOVE TOP MENU WHEN COURSE IS PLAYED*/}
            {/***************************************************/}
            {
                !introPageSkipped ? <style dangerouslySetInnerHTML={{ __html: `.menu-top-container { display: block }` }} /> : <style dangerouslySetInnerHTML={{ __html: `.menu-top-container { display: none }` }} />
            }

            {/***************************************************/}
            {/*DISPLAY INTRO PAGE OR COURSE-PLAY PAGE*/}
            {/***************************************************/}
            {
                !introPageSkipped ?
                    (
                        <CourseIntro
                            skipIntroAndGoToSlide={handleSkipIntroAndGoToSlide}
                            skipIntro={handleSkipIntro}
                            continuousMode={continuousMode}
                            courseData={rawCoursesData}
                            allSondages={allSondages}
                            allSondagesAnswers={allSondagesAnswers}
                            currentRunningSondage={currentRunningSondage}
                            sondageMenuListItem={sondageMenuListItem}
                            lastSlideDone={lastSlideDone}
                            userCourseProgression={userCourseProgression[rawCoursesData.course_tag]}
                            orderedCourseSlidesData={orderedCourseSlidesData}
                            progressPercentage={progressPercentage}
                            meetingRequired={meetingRequired}
                            requestMeetingMode={requestMeetingMode}
                            customDisplayOptionnalLabel={customDisplayOptionnalLabel}
                            verifyModulesRequirementsConditionsBlocked={verifyModulesRequirementsConditionsBlocked}
                            modulesRequirementsConditionsBlockedListIndexes={modulesRequirementsConditionsBlockedListIndexes}
                            bannerFullHeight={bannerFullHeight} />
                    )
                    :
                    (
                        <div className={'play-container'}>

                            {/***************************************************/}
                            {/*COURSE'S SLIDES LEFT MENU*/}
                            {/***************************************************/}
                            <div className={'play-list-menu ' + playListMobileMenuClass + ' ' + (isMobile ? '' : menuHidden ? 'menu-hidden' : '')}>
                                <div className={'progression'}>
                                    <div className={'back-btn'} onClick={handleReturnToIntro} title={t('course-play-page-return-btn')}>
                                        <FontAwesomeIcon className={'back-icon'} icon={faHouse} title={t('course-play-page-return-btn')} />
                                    </div>

                                    <div className={'hide-menu-btn'} onClick={() => handleCloseDeskptoMenu()}>
                                        <FontAwesomeIcon icon={faClose} />
                                    </div>


                                    <div className={'search-btn'} onClick={toggleSearchMenuInput} title={t('course-play-page-search-btn-label')}>
                                        <FontAwesomeIcon className={'search-icon'} icon={faSearch} title={t('course-play-page-search-btn-label')} />
                                    </div>

                                    {
                                        (rawCoursesData.documents && rawCoursesData.documents.length > 0) || (!rawCoursesData.studentGuideButtonDisabled) ?
                                            <div className={'documents-btn'} onClick={handleShowModal} title={t('course-play-page-documents-btn-label')}>
                                                <FontAwesomeIcon className={'documents-icon'} icon={faBook} title={t('course-play-page-documents-btn-label')} />
                                            </div> : null
                                    }

                                    <p className={'course-title'} >{rawCoursesData['course_title']}</p>
                                    <div className={'progress-bar'}>
                                        <div className={'progress-bar-inside'} style={{ width: progressPercentage + '%' }}> </div>
                                    </div>
                                    <div className={'progress-number'}>
                                        {progressPercentage} % {t('course-play-page-progress-done')}
                                    </div>
                                </div>

                                {
                                    displaySearchMenu ?
                                        (
                                            <div className={'search-input-cnt'}>
                                                <input type="text"
                                                    autoFocus
                                                    onBlur={() => {
                                                        setTimeout(() => {
                                                            toggleSearchMenuInput()
                                                        }, 400)
                                                    }}
                                                    onChange={e => scrollSlideMenuOnSearchChange(e.target.value)}
                                                    className={'search-input'}
                                                    placeholder={t('course-play-page-search-btn-label')} />
                                            </div>
                                        ) : null
                                }

                                {
                                    teamsFiltersCourseTags.includes(rawCoursesData.course_tag) && (
                                        <div className={'team-select-dropdown-cnt'}>

                                            <p className={'team-select-dropdown-title'}>
                                                {
                                                    !contactTeamSelected ?
                                                        (rawCoursesData.course_tag === "ADPParcoursinitialsouscripteursPrestigeautomobileethabitation" ? t('filter-message-3') + teamsFiltersModalTexts[parsedQuery.tag].lowerCase + t('filter-message-4') : t('filter-message-1') + teamsFiltersModalTexts[parsedQuery.tag].lowerCase + t('filter-message-2'))
                                                        :
                                                        (teamsFiltersModalTexts[parsedQuery.tag].capitalised + t('selected'))
                                                }
                                            </p>

                                            <Select
                                                className={'team-select-dropdown'}
                                                showSearch
                                                filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                listHeight={500}
                                                placeholder={rawCoursesData.course_tag === 'ADPParcoursinitialsouscripteursPrestigeautomobileethabitation' ? t('filter-placeholder') + teamsFiltersModalTexts[parsedQuery.tag].placeholder : t('filter-placeholder') + teamsFiltersModalTexts[parsedQuery.tag].lowerCase}
                                                value={contactTeamSelected}
                                                onChange={(val) => handleFilterModulesByTeamFilters(val, backupOrderedCourseSlidesData)}>
                                                {
                                                    teamsFilters[parsedQuery.tag].map((team, tIndex) => {
                                                        return (
                                                            <Option key={team.teamLabel}>{team.teamLabel}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    )
                                }


                                <ul>
                                    {
                                        orderedCourseSlidesData.map((el, i) => {

                                            let openNoteBtnClass = allNotesEditorContent[el.id] ? allNotesEditorContent[el.id]['blocks'][0]['text'].length > 0 ? 'open-note-available' : '' : ''
                                            let currentPlayClassConditionWithMeeting = selectedCourseReqMeeting ? el.listIndex === selectedCourseReqMeeting.listIndex && displayMeetingSection : false;
                                            let currentPlayClassCondition = el.listIndex === currentPlayContent.listIndex && currentRunningSondage === null ? 'current-play-el play-el' : 'play-el';

                                            // ******************************************************************
                                            // CUSTOM CONDITION TO DISPLAY WEBZONE MODULE IN CONTACT
                                            if (!state.userInfos.webzone && ['goBRIOestmonoutildetravailprincipalexterne'].includes(parsedQuery.tag) && `${el.id}_${el.playType}` === '264_documents') return null;
                                            // CUSTOM CONDITION TO DISPLAY WEBZONE MODULE IN CONTACT
                                            // ******************************************************************

                                            const blockedModuleRequiredCondition = verifyModulesRequirementsConditionsBlocked(el);
                                            const blockedModuleRequiredConditionListIndexes = blockedModuleRequiredCondition ? modulesRequirementsConditionsBlockedListIndexes(el) : null;

                                            const isCurrentPlayClass = !currentPlayClassConditionWithMeeting && currentPlayClassCondition;
                                            const isDivision = el.playType === 'division';
                                            const isSubDivision = el.sub_division;
                                            const isSlideDone = el.thisSlideDone ? 'true' : el.thisSlideStartDate ? 'partially' : 'false';
                                            const isBlocked = blockedModuleRequiredCondition || el.blockedByProgression;
                                            const opacity = blockedModuleRequiredCondition ? 1 : 0.5;
                                            const canChangePlay = currentPlayContent.listIndex !== el.listIndex || displayMeetingSection || currentRunningSondage !== null;
                                            const handleClick = param => canChangePlay && !isBlocked ? handleCurrentPlayChange({ listIndex: el.listIndex }) : console.log('click on current content');



                                            return (
                                                <>

                                                    {
                                                        i === 0 &&
                                                        allSondages &&
                                                        allSondages.length > 0 &&
                                                        allSondages
                                                            .filter(sondage => sondage.status === 'published' && sondage.prevModuleRelated === null)
                                                            .map((sondage, i) => (
                                                                sondageMenuListItem({
                                                                    currentRunningSondage,
                                                                    sondage,
                                                                    index: i,
                                                                    previousModuleDone: true
                                                                })
                                                            ))
                                                    }

                                                    {
                                                        el.meetingAvailable && requestMeetingMode && (
                                                            <li
                                                                key={i + 'tMeeting'}
                                                                className={(currentPlayClassConditionWithMeeting && currentPlayClassCondition) + ' play-el-division-meeting'}
                                                                done={el.thisSlideDone ? 'true' : el.thisSlideStartDate ? 'partially' : 'false'}
                                                                style={blockedModuleRequiredCondition || el.blockedByProgression ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                                                                onClick={param => currentPlayContent.listIndex !== el.listIndex || !displayMeetingSection ? handleCurrentPlayChange({ listIndex: el.listIndex, forceDisplayMeetingPage: true }) : console.log('click on current content')}
                                                            >
                                                                <span className={'list-title'}>
                                                                    {/* Titre */}
                                                                    <span style={{ color: '#c60c30', fontSize: 15, lineHeight: '17px', padding: '0 0 10px 0', textAlign: 'left', display: 'block', margin: '0 5px 0 0' }}>
                                                                        {
                                                                            isParcoursRencontreAmbassadeurs ?
                                                                                'Rencontres ambassadeurs'
                                                                                :
                                                                                (
                                                                                    meetingRequired ?
                                                                                        t('course-intro-page-course-virtual-meeting-required')
                                                                                        :
                                                                                        t('course-intro-page-course-virtual-meeting-suggested')
                                                                                )
                                                                        }
                                                                    </span>
                                                                    {/* Bouton "M'inscrire" */}
                                                                    <span style={{ backgroundColor: '#c60c30', color: '#fff', fontSize: 14, borderRadius: '5px', padding: '15px', textAlign: 'left', display: 'block', margin: '0 5px 0 0', width: 'fit-content' }}>
                                                                        <span style={{ margin: '0 10px 0 0' }}>
                                                                            <FontAwesomeIcon size='lg' icon={faCalendarAlt} title={t('course-intro-page-course-virtual-meeting-btn-label')} /></span>
                                                                        {meetingRequired ? t('course-intro-page-course-virtual-meeting-required-btn') : t('course-intro-page-course-virtual-meeting-suggested-btn')}</span>
                                                                </span>
                                                            </li>
                                                        )
                                                    }

                                                    <li
                                                        key={i + 't'}
                                                        className={`${isCurrentPlayClass} ${isDivision ? (isSubDivision ? 'play-el-division-sub' : 'play-el-division') : ''}`}
                                                        done={isSlideDone}
                                                        style={isBlocked ? { opacity } : {}}
                                                        onClick={handleClick}
                                                    >
                                                        <span className={'slide-number'}>{i + 1}</span>

                                                        {
                                                            (el.blockedByReqMeeting && parsedQuery.tag !== "RencontreIntactambassadeursContactAP") ? null : el.thisSlideDone === true ? <FontAwesomeIcon className={'done-check'} icon={faCheckCircle} /> : <div className={'current-check'}></div>
                                                        }

                                                        {blockedModuleRequiredCondition || el.blockedByProgression || (el.blockedByReqMeeting && parsedQuery.tag !== "RencontreIntactambassadeursContactAP") ? <FontAwesomeIcon className={'not-done-lock'} icon={faLock} /> : <div className={'not-done-check'}></div>}

                                                        <span className={'list-title'}>
                                                            {el.menuIcon ? (<FontAwesomeIcon className={'list-type-icon'} icon={el.menuIcon} />) : null}
                                                            <span className={'list-title-text'}> {el.title + ' ' + customDisplayOptionnalLabel(el)}</span>
                                                            <span className={'list-title-time'}>{el.titleTime ? '(' + el.titleTime + ')' : null}</span>

                                                            {
                                                                blockedModuleRequiredCondition && (
                                                                    <span className={'list-title-prerequis-text'}>
                                                                        {t('course-play-page-menu-prerequis-text')}
                                                                        < br />
                                                                        <hr />
                                                                        {t('course-play-page-menu-prerequis-text-module-label')}: {blockedModuleRequiredConditionListIndexes}
                                                                    </span>
                                                                )
                                                            }


                                                            {/* {
                                                                el.blockedByReqMeeting ?
                                                                    (
                                                                        <span style={{ backgroundColor: 'red', color: '#fff', fontSize: 13, borderRadius: '5px', padding: '10px', textAlign: 'center', display: 'block', margin: '10px 5px 0 0' }}>Rencontre virtuelle {meetingRequired ? 'requise' : 'suggérée'}</span>
                                                                    ) : null
                                                            } */}

                                                        </span>

                                                        {
                                                            el.thisSlideDone && ['exam', 'exammultiplechoice', 'exercice', 'exercicemultiplechoices', 'dndtext', 'dndimages', 'dndmultiple', 'calculation', 'rafale', 'scorm'].includes(el.playType) && !resetPercentageResults ?
                                                                (
                                                                    <StatsPercentageBox
                                                                        exType={el.playType}
                                                                        exTag={el['exercice_tag'] ? el['exercice_tag'] : el['exam_tag'] ? el['exam_tag'] : null}
                                                                        studentId={parseInt(state.userInfos.id)}
                                                                        courseName={parsedQuery.tag}
                                                                        currentPlayContentId={el['id']}
                                                                        currentPlayIndex={currentPlayContent.listIndex}
                                                                        currentPlayType={currentPlayContent.playType} />
                                                                )
                                                                : null
                                                        }

                                                        <div className={'open-note-btn' + ' ' + openNoteBtnClass}
                                                            onClick={(e) => {
                                                                setCurrentNotesModalCotent({
                                                                    id: el.id,
                                                                    title: el.title,
                                                                })
                                                                setEditorClosed(false)
                                                                if (document.querySelector('.video-player')) document.querySelector('.video-player > video').pause()
                                                                e.stopPropagation();
                                                            }}>
                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                        </div>

                                                        {
                                                            el.additionalInformation && (
                                                                <div className={'additional-information-btn'}
                                                                    onClick={(e) => {
                                                                        handleShowAdditionalInformationModal(el.additionalInformation)
                                                                        e.stopPropagation();
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                </div>

                                                            )
                                                        }
                                                    </li>

                                                    {
                                                        allSondages &&
                                                        allSondages.length > 0 &&
                                                        allSondages
                                                            .filter(sondage => sondage.status === 'published' && sondage.prevModuleRelated === el.moduleContainerId)
                                                            .map((sondage, i) => (
                                                                sondageMenuListItem({
                                                                    currentRunningSondage,
                                                                    sondage,
                                                                    index: i,
                                                                    previousModuleDone: el.thisSlideDone
                                                                })
                                                            ))
                                                    }


                                                </>

                                            )
                                        }
                                        )
                                    }
                                </ul>

                            </div>

                            {/***************************************************/}
                            {/*COURSE START / WELCOME MODULE*/}
                            {/***************************************************/}
                            <StartSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                introPageSkipped={introPageSkipped}
                                menuHiddenContentClass={menuHiddenContentClass}
                                rawCoursesData={rawCoursesData}
                                handleNextSlide={handleNextSlide}
                                handleShowModal={handleShowModal}
                            />

                            {/***************************************************/}
                            {/*COURSE END MODULE*/}
                            {/***************************************************/}

                            <EndSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                menuHiddenContentClass={menuHiddenContentClass}
                                rawCoursesData={rawCoursesData}
                                documentsAndNotesManual={documentsAndNotesManual}
                            />

                            {/***************************************************/}
                            {/*COURSE DIVISION (BLOC) MODULE*/}
                            {/***************************************************/}

                            <DivisionSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                menuHiddenContentClass={menuHiddenContentClass}
                                rawCoursesData={rawCoursesData}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE VIDEO MODULE*/}
                            {/***************************************************/}

                            {
                                !resetModule && (
                                    <VideoPlayerCnt
                                        currentRunningSondage={currentRunningSondage}
                                        menuHiddenContentClass={menuHiddenContentClass}
                                        currentPlayContent={currentPlayContent}
                                        videoDone={videoDone}
                                        resetModule={resetModule}
                                        replayVideoAvailable={replayVideoAvailable}
                                        updateCurrentUserProgression={updateCurrentUserProgression}
                                        handleSlideDoneFromVideo={handleSlideDoneFromVideo}
                                        handleNextSlide={handleNextSlide}
                                        setVideoDone={setVideoDone}
                                    />
                                )
                            }

                            {/***************************************************/}
                            {/*COURSE EXERCICE MODULE*/}
                            {/***************************************************/}

                            <AutoEvaluation1SlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                resetModule={resetModule}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                handleNextSlide={handleNextSlide}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                setResetPercentageResults={setResetPercentageResults}
                            />

                            {/***************************************************/}
                            {/*COURSE EXERCICE MODULE*/}
                            {/***************************************************/}
                            <ExerciceSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                resetModule={resetModule}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                handleNextSlide={handleNextSlide}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                setResetPercentageResults={setResetPercentageResults}
                            />

                            {/***************************************************/}
                            {/*COURSE EXAM MODULE*/}
                            {/***************************************************/}
                            <ExamSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                resetModule={resetModule}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />
                            {/***************************************************/}
                            {/*COURSE EXAM MULTIPLE CHOICE MODULE*/}
                            {/***************************************************/}
                            <ExamMultipleChoiceSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                resetModule={resetModule}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE DRAG AND DROP TEXT MODULE*/}
                            {/***************************************************/}
                            <DndTextSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE DRAG AND DROP MULTIPLE CHOICES MODULE*/}
                            {/***************************************************/}

                            <DndMultipleSlideCnt
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE DRAG AND DROP WITH IMAGE MODULE*/}
                            {/***************************************************/}

                            <DndImagesSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE CALCULATION MODULE*/}
                            {/***************************************************/}

                            <CalculationSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE EN RAFALE GAME MODULE*/}
                            {/***************************************************/}

                            <RafaleSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                resetModule={resetModule}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                            />

                            {/***************************************************/}
                            {/*COURSE EXERCICE WITH OPEN QUESTION MODULE*/}
                            {/***************************************************/}

                            <ExerciceOpenQuestionSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                menuLength={orderedCourseSlidesLength}
                                isMobile={isMobile}
                                resetModule={resetModule}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                                setResetPercentageResults={setResetPercentageResults}
                            />

                            {/***************************************************/}
                            {/*COURSE EXERCICE WITH MULTIPLE CHOICE MODULE*/}
                            {/***************************************************/}

                            <ExerciceMultipleChoicesSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                resetModule={resetModule}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                filesSource={filesSource}
                                setMenuHidden={setMenuHidden}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                handleNextSlide={handleNextSlide}
                                setResetPercentageResults={setResetPercentageResults}
                            />

                            {/***************************************************/}
                            {/*COURSE DOCUMENTS MODULE*/}
                            {/***************************************************/}

                            <DocumentSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                rawCoursesData={rawCoursesData}
                                handleNextSlide={handleNextSlide}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                            />

                            {/***************************************************/}
                            {/*COURSE SCORM MODULE*/}
                            {/***************************************************/}

                            {
                                !resetModule && (
                                    <ScormSlideCnt
                                        currentRunningSondage={currentRunningSondage}
                                        currentPlayContent={currentPlayContent}
                                        isMobile={isMobile}
                                        mobileNotCompatible={mobileNotCompatible}
                                        menuHiddenContentClass={menuHiddenContentClass}
                                        startSlideFromIntro={startSlideFromIntro}
                                        handleNextSlide={handleNextSlide}
                                        setVideoDone={setVideoDone}
                                        setMenuHidden={setMenuHidden}
                                        handleReturnToIntro={handleReturnToIntro}
                                        menuHidden={menuHidden}
                                        updateCurrentUserProgression={updateCurrentUserProgression}
                                        allNotesEditorContent={allNotesEditorContent}
                                        setEditorClosed={setEditorClosed}
                                    />
                                )
                            }


                            {/***************************************************/}
                            {/*COURSE HTML PAGE MODULE*/}
                            {/***************************************************/}

                            <HtmlSlideCnt
                                currentRunningSondage={currentRunningSondage}
                                currentPlayContent={currentPlayContent}
                                isMobile={isMobile}
                                mobileNotCompatible={mobileNotCompatible}
                                menuHiddenContentClass={menuHiddenContentClass}
                                rawCoursesData={rawCoursesData}
                                handleNextSlide={handleNextSlide}
                                updateCurrentUserProgression={updateCurrentUserProgression}
                                isParcoursRencontreAmbassadeurs={isParcoursRencontreAmbassadeurs}
                            />

                            {/***************************************************/}
                            {/*MEETING RESERVATION*/}
                            {/***************************************************/}

                            {
                                !resetModule && requestMeetingMode && displayMeetingSection && (
                                    <MeetingReservation
                                        currentRunningSondage={currentRunningSondage}
                                        allMeetingReservations={allMeetingReservations}
                                        currentPlayContent={currentPlayContent}
                                        rawCoursesData={rawCoursesData}
                                        continuousMode={continuousMode}
                                        requestMeetingMode={requestMeetingMode}
                                        meetingRequired={meetingRequired}
                                        userCourseProgression={userCourseProgression[rawCoursesData['course_tag']]}
                                        setUserCourseRequestedMeetings={setUserCourseRequestedMeetings}
                                        userCourseRequestedMeetings={userCourseRequestedMeetings}
                                        setSelectedCourseReqMeeting={setSelectedCourseReqMeeting}
                                        selectedCourseReqMeeting={selectedCourseReqMeeting}
                                        setDisplayMeetingSection={setDisplayMeetingSection}
                                        displayMeetingSection={displayMeetingSection}
                                        menuHiddenContentClass={menuHiddenContentClass}
                                        isParcoursRencontreAmbassadeurs={isParcoursRencontreAmbassadeurs}
                                    />
                                )
                            }

                            {/***************************************************/}
                            {/*SONDAGE RESERVATION*/}
                            {/***************************************************/}

                            {
                                !resetModule && (
                                    <SondageSlideCnt
                                        currentRunningSondage={currentRunningSondage}
                                        currentRunningSondageAnswers={currentRunningSondageAnswers}
                                        currentPlayContent={currentPlayContent}
                                        rawCoursesData={rawCoursesData}
                                        menuLength={orderedCourseSlidesLength}
                                        isMobile={isMobile}
                                        resetModule={resetModule}
                                        mobileNotCompatible={mobileNotCompatible}
                                        menuHiddenContentClass={menuHiddenContentClass}
                                        filesSource={filesSource}
                                        setMenuHidden={setMenuHidden}
                                        updateCurrentUserProgression={updateCurrentUserProgression}
                                        handleNextSlideFromSondage={handleNextSlideFromSondage}
                                        setResetPercentageResults={setResetPercentageResults}
                                    />
                                )
                            }


                        </div>
                    )
            }

            {/***************************************************/}
            {/*NOTIFICATIONS DISPLAY*/}
            {/***************************************************/}

            {
                notificationStatus !== null && allNotifications && allNotifications.length > 0 ?
                    <div>
                        <div
                            className={notificationHasAlreadyOpen ? 'floatBtn' : 'floatBtnAnimation floatBtn'}
                            onClick={showModalNotification}
                            style={isNotificationModalOpen ? { pointerEvents: 'none' } : {}}
                        >
                            <FontAwesomeIcon className={notificationHasAlreadyOpen ? 'icon' : 'iconAnimation icon'} icon={faBell} />

                        </div>

                        <Modal centered show={isNotificationModalOpen} aria-labelledby="contained-modal-title-vcenter" size="lg">
                            <Modal.Header >
                                <Modal.Title> <div style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon style={{ marginRight: 7 }} icon={faBell} />  <div style={{ display: 'inline-flex', alignItems: 'center' }}>{allNotifications.length > 1 ? `Notifications` : `Notification `}  </div></div></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    allNotifications.map((notification, index) => {
                                        return (
                                            <div key={index}>
                                                <div style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 5 }}> <Tag style={{ height: '300%', width: '300%' }} color={notification.importance === 'Urgent' ? 'red' : 'green'}>{notification.importance}</Tag></div>
                                                <div>
                                                    <div style={{ borderRadius: '7px', padding: 7, marginBottom: 15, minHeight: 125 }} key={notification.id} className={notification.importance === 'Urgent' ? 'html-content urgent-content' : 'html-content standard-content'} dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(notification.content)) }}></div>
                                                </div>
                                            </div>
                                        )

                                    })}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleOkModalNotification}>
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div> : null
            }
            {/***************************************************/}
            {/*NOTE EDITOR OVERLAY*/}
            {/***************************************************/}
            {
                introPageSkipped ?
                    (
                        <NotesEditor
                            lang={state.lang}
                            student={state.userInfos}
                            courseName={parsedQuery.tag}
                            courseTitle={currentNotesModalCotent.title}
                            setEditorClosed={setEditorClosed}
                            editorClosed={editorClosed}
                            playId={currentNotesModalCotent.id}
                            allNotesEditorContent={allNotesEditorContent}
                            setAllNotesEditorContent={setAllNotesEditorContent} />
                    ) : null
            }

            {/***************************************************/}
            {/*DOCUMENTS TO DOWNLOAD MODAL OVERLAY*/}
            {/***************************************************/}

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('course-play-page-documents-btn-label')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        rawCoursesData.documents && rawCoursesData.documents.length > 0 ?
                            (
                                rawCoursesData.documents.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <a href={state.cms2_baseURL + '/assets/' + item.directus_files_id.id} target='_blank' rel="noreferrer" >{item.directus_files_id.title}</a>
                                            <hr />
                                        </div>
                                    )
                                })
                            ) : null
                    }

                    {
                        rawCoursesData.documents_url_external && rawCoursesData.documents_url_external.length > 0 ?
                            (
                                rawCoursesData.documents_url_external.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <a href={item.url} target='_blank' rel="noreferrer" >{item.label}</a>
                                            <hr />
                                        </div>
                                    )
                                })
                            ) : null
                    }

                    {/********DOCUMENT AND NOTES MANUAL*********************************/}
                    {documentsAndNotesManual}
                    {/********DOCUMENT AND NOTES MANUAL*********************************/}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('coursePlayClose')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showAdditionalInformationModal}
                onHide={handleCloseAdditionalInformationModal}
                centered={true}
                size={'xl'}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('course-play-page-addition-information-title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {additionalInformationModalContent && <div dangerouslySetInnerHTML={{ __html: additionalInformationModalContent }}></div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAdditionalInformationModal}>
                        {t('coursePlayClose')}
                    </Button>
                </Modal.Footer>
            </Modal>


            {/***************************************************/}
            {/*CONTACT TEAMS FILTER MODAL OVERLAY*/}
            {/***************************************************/}
            {
                teamsFiltersCourseTags.includes(parsedQuery.tag) && !isNotificationModalOpen && (
                    <Modal
                        backdrop='static'
                        show={!contactTeamSelected && showModalContactFiltersTeam} onHide={handleCloseModalContactFiltersTeam}>
                        <Modal.Header>
                            <Modal.Title>{teamsFiltersModalTexts[parsedQuery.tag].capitalised}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ padding: "0 0 50px 0" }}>

                                <p>
                                    {
                                        !contactTeamSelected ?
                                            (rawCoursesData.course_tag === "ADPParcoursinitialsouscripteursPrestigeautomobileethabitation" ? t('filter-message-3') + teamsFiltersModalTexts[parsedQuery.tag].lowerCase + t('filter-message-4') : t('filter-message-1') + teamsFiltersModalTexts[parsedQuery.tag].lowerCase + t('filter-message-2'))
                                            :
                                            (teamsFiltersModalTexts[parsedQuery.tag].capitalised + t('selected'))
                                    }
                                </p>

                                <Select
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    listHeight={500}
                                    placeholder={rawCoursesData.course_tag === 'ADPParcoursinitialsouscripteursPrestigeautomobileethabitation' ? t('filter-placeholder') + teamsFiltersModalTexts[parsedQuery.tag].placeholder : t('filter-placeholder') + teamsFiltersModalTexts[parsedQuery.tag].lowerCase}
                                    value={contactTeamSelected}
                                    onChange={(val) => handleFilterModulesByTeamFilters(val, backupOrderedCourseSlidesData)}>
                                    {
                                        teamsFilters[parsedQuery.tag].map(team => {
                                            return (
                                                <Option key={team.teamLabel}>{team.teamLabel}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>

                        </Modal.Body>
                    </Modal>
                )
            }

        </div >
    )
};

export default CoursePlay;